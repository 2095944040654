import {
  type DiscardedTopicResponse,
  type DiscardedTopicsResponse,
  type TopicerNewResponse,
  TopicerService,
  UpdateRawTopicCollectionStatusResponse,
} from '../generated'
import { getErrorMsg } from '../helpers'
import { queryClient } from '@/plugins/vue-query'
import { toValue } from 'vue'
import { useMutation, useQuery } from '@tanstack/vue-query'

// All method keys in TopicerService
type TTopicerServiceMethodKeys = ClassMethodKeys<typeof TopicerService>
// Parameter types extractor
export type ArgTypes<K extends TTopicerServiceMethodKeys> = ClassMethodParamType<typeof TopicerService, K>

/***********/
/* QUERIES */
/***********/

export function useListDiscardedTopicsQuery(
  params: ArgTypes<'listDiscardedTopics'>,
  options: TCustomUseQueryOptions<DiscardedTopicsResponse> = {}
) {
  return useQuery({
    queryKey: ['list-discarded-topics'],
    queryFn: () => TopicerService.listDiscardedTopics(params),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useTopicerAsyncTaskStatusQuery(
  params: ArgTypes<'topicerAsyncTaskStatus'>,
  options: TCustomUseQueryOptions<UpdateRawTopicCollectionStatusResponse> = {}
) {
  return useQuery({
    queryKey: ['topicer-async-task-status', params],
    queryFn: () => TopicerService.topicerAsyncTaskStatus(params),
    ...options,
  })
}

/*************/
/* MUTATIONS */
/*************/

type TCreateSectionMutationOptions = TCustomUseMutationOptions<TopicerNewResponse, ArgTypes<'generateTopicCollection'>>
export function useGenerateTopicCollectionMutation(options: TCreateSectionMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => TopicerService.generateTopicCollection(mutationParams),
    // first invalidate queries and than call other onSuccess callbacks
    onSuccess: async (data, variables, context) => {
      const optionsValue = toValue(options)

      if (typeof optionsValue.onSuccess === 'function') {
        optionsValue.onSuccess(data, variables, context)
      }
    },
    onError: (err) => window.$message.error(getErrorMsg(err)),
    ...options,
  })
}

type TCreateDiscardedTopicMutationOptions = TCustomUseMutationOptions<
  DiscardedTopicResponse,
  ArgTypes<'createDiscardedTopic'>
>

export function useCreateDiscardedTopicMutation(options: TCreateDiscardedTopicMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => TopicerService.createDiscardedTopic(mutationParams),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['list-discarded-topics'] })
    },
    onError: (err) => window.$message.error(getErrorMsg(err)),
    ...options,
  })
}

type TDeleteDiscardedTopicMutationOptions = TCustomUseMutationOptions<void, ArgTypes<'deleteDiscardedTopics'>>

export function useDeleteDiscardedTopicMutation(options: TDeleteDiscardedTopicMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => TopicerService.deleteDiscardedTopics(mutationParams),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['list-discarded-topics'] })
    },
    onError: (err) => window.$message.error(getErrorMsg(err)),
    ...options,
  })
}
