<template>
  <NAlert v-bind="$attrs">
    <template #icon>
      <slot name="icon">
        <FaIcon v-if="attrs.type === 'success'" icon="far fa-circle-check" size="xs" />
        <FaIcon v-else-if="attrs.type === 'info'" icon="far fa-circle-info" size="xs" />
        <FaIcon v-else-if="attrs.type === 'warning'" icon="far fa-circle-exclamation" size="xs" />
        <FaIcon v-else-if="attrs.type === 'error'" icon="far fa-circle-xmark" size="xs" />
      </slot>
    </template>

    <template v-if="$slots.header" #header>
      <slot name="header" />
    </template>

    <slot />
  </NAlert>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue'
import type { AlertProps } from 'naive-ui'

export interface CMessageProps extends /* @vue-ignore */ AlertProps {}

defineOptions({
  inheritAttrs: false,
})

defineProps<CMessageProps>()
// kebabize attrs keys, since they are not transformed on the fly
const attrs = useAttrs() as KebabizeKeys<CMessageProps>
</script>

<style lang="scss" scoped></style>
