import TopicSelect from '@/components/topic-assignment/topic/TopicSelect.vue'
import { AuxiliaryFieldUIResponse, CodeSentiments, type RowTopic, type TopicUIResponse } from '@/api'
import { type ComputedRef, type Ref, computed, reactive, ref } from 'vue'
import { EFilterType, type TFilterConfig, type TFilterValue, type TFilters } from '@/components/filters'
import { decodeDateRange, decodeFilterQuery, encodeFilterQuery, encodeStr, renderFaIconText } from '@/utils'
import { flatMap, mapKeys } from 'lodash-es'
import { getTopicSelectOptions, getTopicSelectValue } from '@/composables/useCoding'
import { tolgee } from '@/plugins/i18n'
import { useSentimentMap } from '../insight-elements/helpers'
import type { ArgTypes } from '@/api/vq/projects'

export type TRowListFilters = Partial<{
  topics: string[]
  categories: string[]
  sentiment: string[]
  highlighted: string
  reviewed: string
  empty: string
  withTopics: string
  noTopics: string
}>

type TFilterOptions = {
  'contains.i'?: string[]
  topic?: string[]
  'topic.is_empty'?: string[]
  'topic.category'?: string[]
  sentiment?: string[]
  is_highlighted?: string[]
  was_reviewed?: string[]
  is_empty?: string[]
}

type TSortOption = {
  label: string
  value:
    | 'upload_index'
    | 'last_modified'
    | 'text_to_analyze.was_reviewed'
    | 'text_to_analyze.model_certainty'
    | 'text_to_analyze.value.i'
}

export const sortOptions: TSortOption[] = [
  { label: tolgee.t('sort_options.newest'), value: 'upload_index' },
  { label: tolgee.t('sort_options.updated'), value: 'last_modified' },
  { label: tolgee.t('sort_options.reviewed_first'), value: 'text_to_analyze.was_reviewed' },
  { label: tolgee.t('sort_options.model_certainty'), value: 'text_to_analyze.model_certainty' },
  { label: tolgee.t('sort_options.alpha'), value: 'text_to_analyze.value.i' },
]

const maybeSetFirstArrayChild = (value: string[] | undefined) => {
  return Array.isArray(value) && value.length ? value[0] : undefined
}

const getFilterType = (col: AuxiliaryFieldUIResponse): TFilterConfig['type'] => {
  if (col.type === AuxiliaryFieldUIResponse.type.TEXT && col.metadata.has_few) return EFilterType.Checkbox
  if (col.type === AuxiliaryFieldUIResponse.type.DATE) return EFilterType.Date
  if (col.type === AuxiliaryFieldUIResponse.type.NUMERICAL) return EFilterType.Range
  return EFilterType.SearchInput
}

const isTopicFilter = (val: string) => {
  const filter = val.match(/^topic.(cd_[a-z0-9]+)(:(negative|neutral|positive))?$/)

  return filter ? { parsedTopicKey: 'topic', parsedTopicVal: `${filter[1]}${filter[2] ? filter[2] : ''}` } : {}
}

const decodeTextToAnalyzeParam = (textToAnalyzeParam: string | undefined) => {
  const filterTypes: TFilterOptions = {}

  if (!textToAnalyzeParam) return filterTypes

  textToAnalyzeParam.split(';').forEach((filter) => {
    filter.split(',').forEach((childFilter) => {
      const [filterKey, filterValue] = childFilter.split(':')
      const { parsedTopicKey, parsedTopicVal } = isTopicFilter(childFilter)
      // try to set parsedTopicKey if it is a topic filter, fallback to original filter
      const key = (parsedTopicKey || filterKey) as keyof TFilterOptions
      // try to set parsedTopicValue if it is a topic filter, fallback to original filter
      const value = parsedTopicVal || decodeFilterQuery(filterValue)[0].value

      // create array if undefined
      if (!filterTypes[key]?.length) filterTypes[key] = []

      filterTypes[key]?.push(value)
    })
  })

  return filterTypes
}

const decodeColumnsParam = (columnsParam: string | undefined, questions: ComputedRef<AuxiliaryFieldUIResponse[]>) => {
  const filterTypes: Record<string, TFilterValue> = {}

  if (!columnsParam) return filterTypes

  columnsParam.split(';').forEach((filter) => {
    const singleFilter = filter.split(':')
    const matchResult = singleFilter[0].match(/\w+\[(.+?)\]/)
    const auxiliaryType = matchResult?.[1] ?? ''
    const columnRef = singleFilter[0].split('[')[0]
    const column = questions.value?.find((col) => col.ref === columnRef)

    if (auxiliaryType === 'text') {
      // select filter vs single text filter
      filterTypes[columnRef] = column?.metadata.has_few
        ? filter
            .replaceAll(`${columnRef}[text]:`, '')
            .split(',')
            .map((val) => decodeFilterQuery(val)[0].value)
        : decodeFilterQuery(singleFilter[1])[0].value
    }

    if (auxiliaryType === 'numerical') {
      // range
      const min = columnsParam?.split(`${columnRef}[numerical].gte:`)[1].split(';')[0]
      const max = columnsParam?.split(`${columnRef}[numerical].lte:`)[1].split(';')[0]

      filterTypes[columnRef] = [Number(min), Number(max)]
    }

    if (auxiliaryType === 'date') {
      const dateMinString = columnsParam?.split(`${columnRef}[date].gte:`)[1].split(';')[0].replace(/\\/g, '')
      const dateMaxString = columnsParam?.split(`${columnRef}[date].lte:`)[1].split(';')[0].replace(/\\/g, '')

      if (dateMinString && dateMaxString && Date.parse(dateMaxString) && Date.parse(dateMinString)) {
        const dateMin = new Date(dateMinString).getTime()
        const dateMax = new Date(dateMaxString).getTime()

        filterTypes[columnRef] = [dateMin, dateMax]
      }
    }
  })

  return filterTypes
}

export const useRowBrowserFilters = (
  queryOptions: ArgTypes<'projectCodingRowList'>,
  auxiliaryQuestions: ComputedRef<AuxiliaryFieldUIResponse[]>,
  currentTopics: ComputedRef<TopicUIResponse[] | undefined>,
  currentTopicsDict: ComputedRef<Record<string, TopicUIResponse>>,
  currentCategories: Ref<TCategory[]>,
  handleFiltersUpdate: (
    attr: keyof TRowListFilters | keyof typeof queryOptions,
    val: string | string[] | number[] | undefined
  ) => void,
  searchTranslated: boolean
) => {
  const searchKey = ref('')
  const sortKey = ref('upload_index')
  const sortingDesc = ref(false)
  const sentimentMap = useSentimentMap()
  const sortOrderKey = computed(() => (sortingDesc.value ? `desc:${sortKey.value}` : `asc:${sortKey.value}`))

  // reactive state to hold regular filter values
  const rowListFilters = reactive<TRowListFilters>({
    topics: undefined,
    categories: undefined,
    sentiment: undefined,
    highlighted: undefined,
    reviewed: undefined,
    empty: undefined,
    withTopics: undefined,
    noTopics: undefined,
  })

  // reactive state to hold auxiliaryFilters (dynamic) filter values
  const auxiliaryFilters = reactive<Record<string, TFilterValue>>({
    ...auxiliaryQuestions.value.reduce((obj, col) => ({ ...obj, [col.ref]: undefined }), {}),
  })

  // Dynamically generated filters
  const auxiliaryFiltersConfig = computed<TFilters>(() => {
    return auxiliaryQuestions.value.map((col, index) => {
      return {
        type: getFilterType(col),
        key: `auxiliary.${col.ref}`,
        label: col.name,
        items: col.metadata.values?.filter((v) => v !== ''),
        minimum: col.metadata.min,
        maximum: col.metadata.max,
        value: auxiliaryFilters[col.ref],
        seperatorLabel:
          index === 0 ? tolgee.t('projects.topics_view.row_browser.filters.auxiliary_filters') : undefined,
      }
    }) as TFilters // casting is required here since it is completely dynamic
  })

  // Note: keys here have to match the rowListFilters keys
  const rowsFiltersConfig = computed<TFilters>(() => [
    {
      type: EFilterType.Custom,
      key: 'topics',
      seperatorLabel: tolgee.t('projects.topics_view.row_browser.filters.standard_filters'),
      label: tolgee.t('projects.topics_view.filter_labels.topics'),
      renderTagContent: (item) => {
        const ids = item as string[]
        const [id, sentiment] = ids[0].split(':')

        return ids.length === 1
          ? `${currentTopicsDict.value[id]?.label || ''}${sentiment ? ` ~ ${sentiment}` : ''}`
          : `(${ids.length})`
      },
      component: TopicSelect,
      value: rowListFilters.topics,
      props: {
        value: getTopicSelectValue(rowListFilters.topics, currentTopics.value),
        noTopics: rowListFilters.noTopics,
        withTopics: rowListFilters.withTopics,
        disabled: !!rowListFilters.noTopics || !!rowListFilters.withTopics,
        options: getTopicSelectOptions(currentTopics.value, rowListFilters.topics),
        emitIds: true,
        modalOpen: true,
        allowNewTopic: false,
        disabledSentiment: false,
        optionalSentiment: true,
        hideLabels: true,
        additionalFilters: true,
        'onUpdate:value': (val: RowTopic[]) => {
          handleFiltersUpdate(
            'topics',
            val.map((t) => `${t.id}${t.sentiment ? `:${t.sentiment}` : ''}`)
          )
        },
        'onUpdate:noTopics': (val: boolean) => {
          handleFiltersUpdate('noTopics', val ? 'True' : undefined)
          if (val) rowListFilters.topics = []
        },
        'onUpdate:withTopics': (val: boolean) => {
          handleFiltersUpdate('withTopics', val ? 'True' : undefined)
        },
      },
    },
    {
      type: EFilterType.Checkbox,
      key: 'categories',
      label: tolgee.t('projects.topics_view.filter_labels.categories'),
      items: currentCategories.value,
      // TO DO: once categories are separate entity, this should be the id of categories
      itemValueKey: 'label',
      searchItemKey: 'label',
      value: rowListFilters.categories,
      renderCheckboxContent: (item: TCategory) => item.label,
    },
    {
      type: EFilterType.Checkbox,
      key: 'sentiment',
      label: tolgee.t('projects.topics_view.filter_labels.sentiment'),
      items: flatMap(CodeSentiments),
      value: rowListFilters.sentiment,
      searchable: false,
      renderCheckboxContent: (item: CodeSentiments) => {
        const sentimentInfo = sentimentMap.getSentimentInformation(item)

        return renderFaIconText(tolgee.t(`projects.topics_view.sentiment_${item}`), sentimentInfo.icon, {
          faIconProps: {
            color: sentimentInfo.color,
          },
        })
      },
    },
    {
      type: EFilterType.Checkbox,
      key: 'highlighted',
      label: tolgee.t('projects.topics_view.filter_labels.highlight'),
      searchable: false,
      multiple: false,
      items: [
        { label: tolgee.t('common.yes'), value: 'True' },
        { label: tolgee.t('common.no'), value: 'False' },
      ],
      value: rowListFilters.highlighted,
      renderCheckboxContent: (item) => item.label,
      // item here is coming from items of this filter
      renderTagContent: (item) => ((item as string) === 'True' ? tolgee.t('common.yes') : tolgee.t('common.no')),
    },
    {
      type: EFilterType.Checkbox,
      key: 'reviewed',
      label: tolgee.t('projects.topics_view.filter_labels.reviewed'),
      searchable: false,
      multiple: false,
      items: [
        { label: tolgee.t('common.yes'), value: 'True' },
        { label: tolgee.t('common.no'), value: 'False' },
      ],
      value: rowListFilters.reviewed,
      renderCheckboxContent: (item) => item.label,
      // item here is coming from items of this filter
      renderTagContent: (item) => ((item as string) === 'True' ? tolgee.t('common.yes') : tolgee.t('common.no')),
    },
    {
      type: EFilterType.Checkbox,
      key: 'empty',
      label: tolgee.t('projects.topics_view.filter_labels.empty'),
      searchable: false,
      multiple: false,
      items: [
        { label: tolgee.t('projects.topics_view.filter_labels.show_empty'), value: 'True' },
        { label: tolgee.t('projects.topics_view.filter_labels.show_non_empty'), value: 'False' },
      ],
      value: rowListFilters.empty,
      renderCheckboxContent: (item) => item.label,
      // item here is coming from items of this filter
      renderTagContent: (item) => ((item as string) === 'True' ? tolgee.t('common.yes') : tolgee.t('common.no')),
    },
    {
      type: EFilterType.Date,
      key: 'created',
      label: tolgee.t('projects.topics_view.filter_labels.created'),
      value: queryOptions.created ? decodeDateRange(queryOptions.created) : undefined,
    },
    // merge with auxiliaryFilters here
    ...auxiliaryFiltersConfig.value,
  ])

  /**
   * This method is called when RowBrowser component is mounted OR when user navigates through back/forward buttons
   * Purpose of the method is to synchronize updated URLParams with local state
   **/
  const setFilterValuesFromQuery = (URLParams: Partial<ArgTypes<'projectCodingRowList'>>) => {
    // Sorting filter
    sortingDesc.value = URLParams.orderBy?.split(':')[0] === 'desc'
    sortKey.value = URLParams.orderBy?.split(':')[1] || 'upload_index'

    // textToAnalyze filters
    const textToAnalyzeFilters = decodeTextToAnalyzeParam(URLParams.textToAnalyze)
    const columnFilters = decodeColumnsParam(URLParams.columns, auxiliaryQuestions)

    // set searchKey values from params to sync localState with params
    searchKey.value = textToAnalyzeFilters['contains.i']?.length ? textToAnalyzeFilters['contains.i'][0] : ''
    // set rowListFilters values from params to sync localState with params
    rowListFilters.topics = textToAnalyzeFilters.topic
    rowListFilters.categories = textToAnalyzeFilters['topic.category']
    rowListFilters.sentiment = textToAnalyzeFilters.sentiment
    rowListFilters.highlighted = maybeSetFirstArrayChild(textToAnalyzeFilters.is_highlighted)
    rowListFilters.reviewed = maybeSetFirstArrayChild(textToAnalyzeFilters.was_reviewed)
    rowListFilters.empty = maybeSetFirstArrayChild(textToAnalyzeFilters.is_empty)

    if (textToAnalyzeFilters['topic.is_empty']) {
      if (textToAnalyzeFilters['topic.is_empty'][0] === 'True') {
        rowListFilters.noTopics = 'True'
      } else {
        rowListFilters.withTopics = 'True'
      }
    }

    // set auxiliary filter values from params to sync localState with params
    for (const key in auxiliaryFilters) auxiliaryFilters[key] = columnFilters[key]
  }

  /**
   * this method is called each time there is a change in the defined filters
   * takes filter values and generates query string for the API
   **/
  const generateTextToAnalyzeQueryFromFilters = () => {
    const textToAnalyzeFilters: Array<string> = []

    if (searchKey.value) {
      textToAnalyzeFilters.push(
        // eslint-disable-next-line max-len, vue/max-len
        `${searchTranslated ? 'translated_value.' : ''}contains.i:${encodeFilterQuery([{ variable: null, value: searchKey.value }])}`
      )
    }

    // loop through each rowListFilter
    for (const [key, val] of Object.entries(rowListFilters)) {
      const type = key as keyof TRowListFilters

      if (type === 'topics') {
        let topicQueryArray: (string | undefined)[] = []

        if (rowListFilters.noTopics) {
          topicQueryArray.push('topic.is_empty:True')
        } else {
          // we know that topics filter value will always be an array, hence we can cast the type here
          if (Array.isArray(val) && val.length) {
            const values = val as string[]

            topicQueryArray = values.map((tId) => {
              const topic = currentTopicsDict.value[tId.split(':')[0]]
              const withSentiment = tId.split(':')[1]

              return topic ? (withSentiment ? `topic.${tId}` : `topic:${topic.id}`) : undefined
            })
          }

          if (rowListFilters.withTopics) {
            topicQueryArray.push('topic.is_empty:False')
          }
        }

        if (topicQueryArray.length) textToAnalyzeFilters.push(topicQueryArray.join(','))
      }

      // TO DO: Once categories are entities + topic select component is implemented this will need to be replaced.
      if (type === 'categories' && Array.isArray(val) && val.length) {
        textToAnalyzeFilters.push(val.map((cat) => `topic.category:${encodeStr(cat as string)}`).join(','))
      }

      if (type === 'sentiment' && Array.isArray(val) && val.length) {
        textToAnalyzeFilters.push(val.map((sentiment) => `sentiment:${sentiment}`).join(','))
      }

      if (type === 'highlighted' && typeof val === 'string') {
        textToAnalyzeFilters.push(`is_highlighted:${val}`)
      }

      if (type === 'reviewed' && typeof val === 'string') {
        textToAnalyzeFilters.push(`was_reviewed:${val}`)
      }

      if (type === 'empty' && typeof val === 'string') {
        textToAnalyzeFilters.push(`is_empty:${val}`)
      }
    }

    return textToAnalyzeFilters.length ? textToAnalyzeFilters.join(';') : undefined
  }

  /**
   * this method is called each time there is a change in the auxiliary/dynamic filters
   * takes filter values and generates query string for the API
   **/
  const generateColumnsQueryFromFilters = () => {
    const columnFilters: Array<string> = []

    for (const [type, val] of Object.entries(auxiliaryFilters)) {
      const column = auxiliaryQuestions.value.find((c) => c.ref === type)

      if (!column || !val || !val.length) continue

      const filterType = getFilterType(column)

      if (filterType === EFilterType.Checkbox) {
        // in most cases, value here will be a string array
        // but to make sure check the type and parse regardingly
        const parsedValue = Array.isArray(val)
          ? val.map((v) => `${column.ref}[text]:${encodeStr(String(v))}`).join(',')
          : `${column.ref}[text]:${encodeStr(val)}`

        columnFilters.push(parsedValue)
      }

      if (filterType === 'date') {
        const [start, end] = val as number[]
        const startDate = new Date(start).toISOString().replace(/:/g, '\\:')
        const endDate = new Date(end).toISOString().replace(/:/g, '\\:')

        columnFilters.push(`${column.ref}[date].gte:${startDate};${column.ref}[date].lte:${endDate}`)
      }

      if (filterType === 'range') {
        const [start, end] = val as number[]

        columnFilters.push(`${column.ref}[numerical].gte:${start};${column.ref}[numerical].lte:${end}`)
      }

      if (filterType === 'search-input') {
        const value = encodeStr(val as string)

        // TO DO: not sure if we need `column.type` here instead of text? figure out
        columnFilters.push(`${column.ref}[text].contains.i:${value}`)
      }
    }

    return columnFilters.length ? columnFilters.join(';') : undefined
  }

  const reset = () => {
    searchKey.value = ''
    sortKey.value = 'upload_index'
    sortingDesc.value = false

    // reset each filter data
    mapKeys(rowListFilters, (val, type) => (rowListFilters[type as keyof typeof rowListFilters] = undefined))
    mapKeys(auxiliaryFilters, (val, type) => (auxiliaryFilters[type] = undefined))
  }

  return {
    searchKey,
    sortKey,
    sortOrderKey,
    sortingDesc,
    rowListFilters,
    auxiliaryFilters,
    rowsFiltersConfig,

    setFilterValuesFromQuery,
    generateTextToAnalyzeQueryFromFilters,
    generateColumnsQueryFromFilters,
    reset,
  }
}
