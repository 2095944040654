/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum DetectableEntitiesWDefault {
  DEFAULT = 'default',
  ACCOUNT_NUMBER = 'account_number',
  AGE = 'age',
  DATE = 'date',
  DATE_INTERVAL = 'date_interval',
  DOB = 'dob',
  DRIVER_LICENSE = 'driver_license',
  DURATION = 'duration',
  EMAIL_ADDRESS = 'email_address',
  EVENT = 'event',
  FILENAME = 'filename',
  GENDER = 'gender',
  HEALTHCARE_NUMBER = 'healthcare_number',
  IP_ADDRESS = 'ip_address',
  LANGUAGE = 'language',
  LOCATION = 'location',
  LOCATION_ADDRESS = 'location_address',
  LOCATION_ADDRESS_STREET = 'location_address_street',
  LOCATION_CITY = 'location_city',
  LOCATION_COORDINATE = 'location_coordinate',
  LOCATION_COUNTRY = 'location_country',
  LOCATION_STATE = 'location_state',
  LOCATION_ZIP = 'location_zip',
  MARITAL_STATUS = 'marital_status',
  MONEY = 'money',
  NAME = 'name',
  NUMERICAL_PII = 'numerical_pii',
  OCCUPATION = 'occupation',
  ORGANIZATION = 'organization',
  ORIGIN = 'origin',
  PASSPORT_NUMBER = 'passport_number',
  PASSWORD = 'password',
  PHONE_NUMBER = 'phone_number',
  PHYSICAL_ATTRIBUTE = 'physical_attribute',
  POLITICAL_AFFILIATION = 'political_affiliation',
  RELIGION = 'religion',
  SEXUALITY = 'sexuality',
  SSN = 'ssn',
  TIME = 'time',
  URL = 'url',
  USERNAME = 'username',
  VEHICLE_ID = 'vehicle_id',
  ZODIAC_SIGN = 'zodiac_sign',
  BLOOD_TYPE = 'blood_type',
  CONDITION = 'condition',
  DOSE = 'dose',
  DRUG = 'drug',
  INJURY = 'injury',
  MEDICAL_PROCESS = 'medical_process',
  STATISTICS = 'statistics',
  BANK_ACCOUNT = 'bank_account',
  CREDIT_CARD = 'credit_card',
  CREDIT_CARD_EXPIRATION = 'credit_card_expiration',
  CVV = 'cvv',
  ROUTING_NUMBER = 'routing_number',
}
