import { AvailableWidgetId, ColumnKind, type ReportSummaryInsightElementResponse, type SingleValues } from '@/api'

export const mockReportSummaryInsightElementResponse: ReportSummaryInsightElementResponse = {
  id: '1',
  kind: 'ReportSummaryInsight',
  section_id: '1',
  order_id: 1,
  created: '2021-09-01T00:00:00Z',
  last_modified: '2021-09-01T00:00:00Z',
  overall_rows: {
    id: '1',
    kind: 'ReportSummaryWidget',
    name: 'Overall Rows',
    insight_element_id: '1',
    available_identifier: AvailableWidgetId.OVERALL_ROWS,
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
  overall_nps_score: {
    id: '2',
    kind: 'ReportSummaryWidget',
    name: 'Overall NPS Score',
    insight_element_id: '1',
    available_identifier: AvailableWidgetId.OVERALL_NPS_SCORE,
    widget_config: {
      id: 'wg_2',
      widget_id: 'wg_2',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
  overall_recommend: {
    id: '3',
    kind: 'ReportSummaryWidget',
    name: 'Overall Recommend',
    insight_element_id: '1',
    available_identifier: AvailableWidgetId.OVERALL_RECOMMEND,
    widget_config: {
      id: 'wg_3',
      widget_id: 'wg_3',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
  sentiment_toggle: true,
  filters: [],
  available_filters: {},
}

export const createWidgetMockData = (): SingleValues => ({
  kind: 'single-value-data',
  results: [
    {
      value: Math.floor(Math.random() * 100) + 1,
      segment_id: '1',
      trend_value: 100,
    },
  ],
})
