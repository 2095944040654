<template>
  <NModal :show="modelValue" :mask-closable="false">
    <NCard
      :class="`w-full ${!anonymizeClassesStep ? 'max-w-[450px]' : 'max-w-[650px]'}`"
      closable
      :title="
        anonymizeClassesStep ? $t('projects.new_project.anonymization_step_title') : $t('projects.new_project.new')
      "
      rounded-lg
      size="small"
      @close="closeModal"
    >
      <NSpin :show="isSubmitting" class="w-full">
        <NForm v-if="!anonymizeClassesStep">
          <NGrid cols="12" item-responsive :x-gap="12" responsive="screen">
            <NFormItemGi span="12" :label="$t('projects.new_project.name')" path="name" v-bind="nameAttrs.fieldAttrs">
              <NInput v-model:value="name" placeholder="" v-on="nameAttrs.inputListeners" />
            </NFormItemGi>
            <NFormItemGi
              span="12"
              :label="$t('projects.new_project.language')"
              path="language"
              v-bind="languageAttrs.fieldAttrs"
            >
              <NSelect
                v-model:value="language"
                filterable
                :options="languageOptions"
                v-on="languageAttrs.inputListeners"
              />
            </NFormItemGi>
            <NFormItemGi
              span="12"
              label=""
              path="anonymization"
              :show-label="false"
              :show-feedback="false"
              v-bind="anonymizationAttrs.fieldAttrs"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <label class="c-header-text-color mr-1">{{ $t('projects.new_project.anonymization') }}</label>
                  <InfoIconTooltip
                    icon="far fa-circle-info"
                    size="sm"
                    :button-props="{ type: 'default' }"
                    :tooltip-props="{ placement: 'right' }"
                  >
                    <span v-html="$t('projects.new_project.anonymization_helptip')" />
                  </InfoIconTooltip>
                </div>
                <NSwitch v-model:value="anonymization" placeholder="" v-on="anonymizationAttrs.inputListeners" />
              </div>
            </NFormItemGi>
            <NFormItemGi
              span="12"
              label=""
              path="translations_enabled"
              :show-label="false"
              :show-feedback="false"
              v-bind="translationsEnabledAttrs.fieldAttrs"
              :class="translationsEnabled ? 'mb-0' : 'mb-4'"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <label class="c-header-text-color mr-1">{{ $t('projects.new_project.translations') }}</label>
                  <InfoIconTooltip
                    :tooltip-props="{ placement: 'right' }"
                    icon="far fa-circle-info"
                    size="sm"
                    :button-props="{ type: 'default' }"
                  >
                    <span v-html="$t('projects.new_project.translations_helptip')" />
                  </InfoIconTooltip>
                </div>
                <NSwitch
                  v-model:value="translationsEnabled"
                  placeholder=""
                  v-on="translationsEnabledAttrs.inputListeners"
                />
              </div>
            </NFormItemGi>
            <NFormItemGi
              v-if="translationsEnabled"
              span="12"
              :label="$t('projects.new_project.translation_engine')"
              path="translation_engine"
              v-bind="translationEngineAttrs.fieldAttrs"
              class="mt-2 flex-col"
            >
              <NSelect
                v-model:value="translationEngine"
                :options="translationsEngineOptions"
                v-on="translationEngineAttrs.inputListeners"
              />
            </NFormItemGi>
          </NGrid>
          <CMessage v-if="translationsEnabled" class="mb-3" type="info">
            <span
              v-html="
                translationEngine === ProjectCreateUIRequest.translation_engine.GOOGLE_TRANSLATE
                  ? $t('projects.new_project.translations_info_google')
                  : $t('projects.new_project.translations_info_microsoft')
              "
            />
          </CMessage>
        </NForm>
        <div v-else>
          <div class="mb-4">{{ $t('projects.new_project.anonymization_description') }}</div>
          <NCheckboxGroup v-model:value="enabledAnonymizeClasses">
            <NGrid :y-gap="8" :cols="3">
              <NGi v-for="entity in DEFAULT_ANONYMIZATION_CLASSES" :key="entity">
                <NCheckbox :value="entity">
                  <div class="flex items-center gap-1">
                    {{ $t(`projects.new_project.anonymization_classes.${entity}`) }}
                    <InfoIconTooltip
                      :tooltip-props="{ placement: 'right' }"
                      icon="far fa-circle-info"
                      size="sm"
                      :button-props="{ type: 'default' }"
                    >
                      <span v-html="$t(`projects.new_project.anonymization_classes.${entity}_description`)" />
                    </InfoIconTooltip>
                  </div>
                </NCheckbox>
              </NGi>
            </NGrid>
          </NCheckboxGroup>

          <NCollapse arrow-placement="right" class="my-4">
            <NCollapseItem name="advanced-settings">
              <template #header>
                <div>
                  <div class="font-500">
                    {{ $t('projects.new_project.advanced_settings') }}
                  </div>
                  <div class="c-default-text-color">
                    {{ $t('projects.new_project.advanced_settings_description') }}
                  </div>
                </div>
              </template>
              <NCheckboxGroup v-model:value="enabledAdvancedAnonymizeClasses">
                <NGrid :y-gap="8" :cols="3">
                  <NGi v-for="entity in ADVANCED_ANONYMIZATION_CLASSES" :key="entity">
                    <NCheckbox :value="entity">
                      <div class="flex items-center gap-1">
                        {{ $t(`projects.new_project.anonymization_classes.${entity}`) }}
                        <InfoIconTooltip
                          :tooltip-props="{ placement: 'right' }"
                          icon="far fa-circle-info"
                          size="sm"
                          :button-props="{ type: 'default' }"
                        >
                          <span v-html="$t(`projects.new_project.anonymization_classes.${entity}_description`)" />
                        </InfoIconTooltip>
                      </div>
                    </NCheckbox>
                  </NGi>
                </NGrid>
              </NCheckboxGroup>
              <NDivider class="!my-3" />
              <div class="mb-2">{{ $t('projects.new_project.allow_classes_description') }}</div>
              <NDynamicTags v-model:value="allowList" class="mb-4" />
            </NCollapseItem>
          </NCollapse>
        </div>

        <div class="flex justify-end">
          <NButton
            v-if="anonymizeClassesStep"
            type="default"
            size="small"
            class="mr-2"
            @click="
              () => {
                anonymization = false
                anonymizeClassesStep = false
              }
            "
          >
            {{ $t('common.back') }}
          </NButton>
          <NButton type="primary" size="small" :disabled="!isFormValid || isPending" @click="createProject">
            {{ $t('common.continue') }}
          </NButton>
        </div>
      </NSpin>
    </NCard>
  </NModal>
</template>

<script setup lang="ts">
import InfoIconTooltip from '@/components/InfoIconTooltip.vue'
import { DetectableEntitiesWDefault, ProjectCreateUIRequest } from '@/api'
import { boolean, object, string } from 'yup'
import { computed, ref, watch } from 'vue'
import { tolgee } from '@/plugins/i18n'
import { useProjectCreateMutation } from '@/api/vq/projects'
import { useRouter } from 'vue-router'
import { useTranslate } from '@tolgee/vue'
import { useValidatedForm } from '@/plugins/form-validation/'

const props = defineProps<{
  modelValue?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [value: boolean | undefined]
}>()

const DEFAULT_ANONYMIZATION_CLASSES = [
  DetectableEntitiesWDefault.DRIVER_LICENSE,
  DetectableEntitiesWDefault.EMAIL_ADDRESS,
  DetectableEntitiesWDefault.HEALTHCARE_NUMBER,
  DetectableEntitiesWDefault.LOCATION_ADDRESS,
  DetectableEntitiesWDefault.NAME,
  DetectableEntitiesWDefault.NUMERICAL_PII,
  DetectableEntitiesWDefault.PASSPORT_NUMBER,
  DetectableEntitiesWDefault.PASSWORD,
  DetectableEntitiesWDefault.PASSPORT_NUMBER,
  DetectableEntitiesWDefault.PHONE_NUMBER,
  DetectableEntitiesWDefault.USERNAME,
  DetectableEntitiesWDefault.BANK_ACCOUNT,
  DetectableEntitiesWDefault.CREDIT_CARD,
]

const ADVANCED_ANONYMIZATION_CLASSES = Object.values(DetectableEntitiesWDefault).filter(
  (entity) => entity !== DetectableEntitiesWDefault.DEFAULT && !DEFAULT_ANONYMIZATION_CLASSES.includes(entity)
)

const { t } = useTranslate()
const router = useRouter()

const anonymizeClassesStep = ref(false)
const enabledAnonymizeClasses = ref(DEFAULT_ANONYMIZATION_CLASSES)
const enabledAdvancedAnonymizeClasses = ref([])
const allowList = ref([])

const {
  isFormValid,
  formFields: {
    name: [name, nameAttrs],
    language: [language, languageAttrs],
    translations_enabled: [translationsEnabled, translationsEnabledAttrs],
    translation_engine: [translationEngine, translationEngineAttrs],
    anonymization: [anonymization, anonymizationAttrs],
  },
  resetForm,
  handleSubmit,
  isSubmitting,
} = useValidatedForm(
  object({
    name: string().required().min(3),
    language: string().required(),
    translations_enabled: boolean().required(),
    translation_engine: string(),
    anonymization: boolean().required(),
  }),
  {
    name: '',
    language: 'en',
    translations_enabled: false,
    translation_engine: ProjectCreateUIRequest.translation_engine.GOOGLE_TRANSLATE,
    anonymization: false,
  }
)

const { mutateAsync: createProjectMutaton, isPending } = useProjectCreateMutation()

const languageOptions = computed(() => {
  return Object.values(ProjectCreateUIRequest.language).map((lang) => ({
    label: tolgee.unwrap(t.value(`common.language.${lang}`)).text,
    value: lang,
  }))
})

const translationsEngineOptions = computed(() => {
  return Object.values(ProjectCreateUIRequest.translation_engine).map((engine) => ({
    label: tolgee.unwrap(t.value(`common.translation_engine.${engine}`)).text,
    value: engine,
  }))
})

const closeModal = () => {
  emit('update:modelValue', false)
}

const createProject = handleSubmit(async (values) => {
  if (values.anonymization && !anonymizeClassesStep.value) {
    anonymizeClassesStep.value = true
    return
  }

  const project = await createProjectMutaton({
    requestBody: {
      name: values.name,
      language: values.language,
      translation_engine: values.translations_enabled ? values.translation_engine : undefined,
      anonymize_pii: values.anonymization
        ? {
            enabled_classes: [...enabledAnonymizeClasses.value, ...enabledAdvancedAnonymizeClasses.value],
            allow_list: allowList.value,
          }
        : undefined,
    } as ProjectCreateUIRequest,
  })

  closeModal()
  router.push(`/projects/${project.id}/upload`)
})

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      anonymizeClassesStep.value = false
      enabledAnonymizeClasses.value = DEFAULT_ANONYMIZATION_CLASSES
      enabledAdvancedAnonymizeClasses.value = []
      allowList.value = []

      resetForm({
        values: {
          name: '',
          language: 'en',
        },
      })
    }
  }
)
</script>

<style lang="scss" scoped>
:deep(.n-collapse-item__header-main) {
  justify-content: space-between;
}
</style>
