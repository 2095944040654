/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ThemeResponse } from '../models/ThemeResponse';
import type { ThemeUpdateRequest } from '../models/ThemeUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ThemeService {
  /**
   * Return the Theme for the user
   * Return the Theme for the user
   * @returns ThemeResponse OK
   * @throws ApiError
   */
  public static themeRetrieve(): CancelablePromise<ThemeResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/theme/',
    });
  }
  /**
   * Update the Theme for the user
   * Update the Theme for the user
   * @returns ThemeResponse OK
   * @throws ApiError
   */
  public static themeUpdate({
    requestBody,
  }: {
    requestBody: ThemeUpdateRequest,
  }): CancelablePromise<ThemeResponse> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/theme/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
