<template>
  <NPopover
    v-model:show="tagMenuOpen"
    trigger="click"
    placement="bottom"
    :style="'padding: 0; border-radius: 12px; overflow: hidden'"
  >
    <template #trigger>
      <slot name="activator">
        <NButton dashed type="default" size="tiny">
          <FaIcon icon="fa-plus" size="sm" class="mr-2" />
          {{ $t('projects.table.new_tag') }}
        </NButton>
      </slot>
    </template>

    <!-- TAGS DROPDOWN -->
    <NLayout>
      <CheckboxList
        :value="selectedTags"
        :items="filteredTags"
        :loading="isPending"
        search-item-key="name"
        @update:value="handleTagUpdate"
      >
        <template #input>
          <div class="pa-2">
            <NInput
              v-model:value="tagSearchKey"
              size="small"
              :placeholder="$t('projects.datatable.tag_search_create_placeholder')"
              clearable
              @keydown.esc="tagSearchKey = ''"
            >
              <template #prefix>
                <NIcon>
                  <FaIcon icon="fa-search" size="xs" />
                </NIcon>
              </template>
            </NInput>
          </div>
          <NDivider class="!mb-0 !mt-0" />
        </template>

        <template #checkboxContent="{ item }">
          <TagItem :tag="(item as ProjectListUIConfigTabResponse).name" />
        </template>

        <!-- No search result -->
        <template #no-data>
          <div class="text-center">
            <div>{{ $t('projects.datatable.no_tags') }}</div>
            <NButton
              v-if="tagSearchKey.length"
              class="mt-3"
              secondary
              type="primary"
              size="small"
              @click="createAndApplyTag"
            >
              {{ $t('projects.datatable.create_tag') }}: '{{ tagSearchKey }}'
            </NButton>
          </div>
        </template>
      </CheckboxList>
    </NLayout>
  </NPopover>
</template>

<script setup lang="ts">
import CheckboxList from '@/components/CheckboxList.vue'
import TagItem from '@/components/TagItem.vue'
import { computed, ref } from 'vue'
import { intersection } from 'lodash-es'
import { useProjectUpdateMutation } from '@/api/vq/projects'
import type { ProjectDetailUIResponse, ProjectListUIConfigTabResponse, ProjectListUIResponse } from '@/api'

interface IProjectsTagsDropdownProps {
  projects: Array<ProjectDetailUIResponse | ProjectListUIResponse> | ProjectDetailUIResponse | ProjectListUIResponse
  availableTags: ProjectListUIConfigTabResponse[]
}

const props = withDefaults(defineProps<IProjectsTagsDropdownProps>(), {
  availableTags: () => [],
})

const tagSearchKey = ref('')
const tagMenuOpen = ref(false)
const filteredTags = computed(() => {
  let tags = props.availableTags

  if (tagSearchKey.value) {
    tags = props.availableTags.filter((tag) => tag.name.includes(tagSearchKey.value))
  }
  return tags
})

const { mutateAsync } = useProjectUpdateMutation()
const isPending = ref(false)

const selectedTags = computed(() => {
  if (!Array.isArray(props.projects)) return props.projects.labels
  return intersection(...props.projects.map((p) => p.labels))
})

const handleTagUpdate = async (tags: string[], resetSearchKey?: boolean) => {
  isPending.value = true

  const projects = Array.isArray(props.projects) ? props.projects : [props.projects]

  Promise.all(
    projects.map((p) =>
      mutateAsync({
        id: p.id,
        requestBody: {
          labels: tags,
        },
      })
    )
  ).finally(() => {
    isPending.value = false
    if (resetSearchKey) {
      tagSearchKey.value = ''
    }
  })
}

const createAndApplyTag = async () => {
  handleTagUpdate([...selectedTags.value, tagSearchKey.value], true)
}
</script>

<style lang="scss" scoped></style>
