<template>
  <NPopover
    :show="dropdownVisibleModel"
    trigger="click"
    placement="bottom-start"
    raw
    :show-arrow="false"
    class="rounded-xl"
    @update:show="handleDropdownVisibleUpdate"
  >
    <template #trigger>
      <div class="line-height-0">
        <slot name="activator">
          <NButton
            ghost
            type="info"
            class="c-default-text-color"
            size="small"
            :bordered="false"
            :disabled="filtersLoading"
            :loading="filtersLoading"
          >
            <span class="mr-1 text-sm">{{ $t('common.filters') }}</span>
            <FaIcon class="c-transition" icon="fa-chevron-down" :rotation="dropdownVisibleModel ? 180 : undefined" />
          </NButton>
        </slot>
      </div>
    </template>

    <div v-if="filteredFiltersConfig.length" class="bg-card-color b b-divider rounded-xl">
      <NLayout has-sider class="rounded-xl">
        <!-- MENU LEFT SIDE -->
        <NLayoutSider class="bg-card-color filter-list" content-class="p-0 max-h-500px" :native-scrollbar="false">
          <div class="flex flex-col gap-1 py-2">
            <div v-if="searchable" class="px-3 pb-1">
              <SearchInput v-model:value="searchValue" size="small" input-class="!w-100" />
            </div>
            <div v-for="filter in filteredFiltersConfig" :key="filter.key">
              <div
                v-if="filter.seperatorLabel && filter.seperatorLabel.length"
                class="b-t-1 b-b-1 b-solid b-divider font-500 mb-2 px-3 py-2 text-xs"
              >
                {{ filter.seperatorLabel }}
              </div>
              <div class="px-2">
                <NButton
                  class="filter-list__item pl-5"
                  :class="{ selected: selectedFilterKey === filter.key }"
                  block
                  quaternary
                  :type="selectedFilterKey === filter.key ? 'info' : undefined"
                  @click="changeSelectedFilter(filter.key)"
                >
                  <NBadge
                    v-if="Array.isArray(filter.value) && filter.value && filter.value.length"
                    class="absolute"
                    :offset="[-8, 0]"
                    :color="primaryColor"
                    :style="`border-color: ${primaryColor}`"
                    dot
                  />

                  <TruncatedTooltip
                    :label="filter.label"
                    trigger-class="font-500 truncate leading-normal mr-2"
                    :length="150"
                  />

                  <FaIcon icon="fa-chevron-right" class="c-default-text-color ml-auto" size="xs" />
                </NButton>
              </div>
            </div>
          </div>
        </NLayoutSider>

        <NLayout
          class="filter-content bg-card-color border-l-1 b-solid b-divider"
          content-class="flex flex-col justify-between"
        >
          <template v-for="filter in filtersConfig" :key="filter.key">
            <!-- SELECT ITEM -->
            <div v-if="filter.type === EFilterType.Checkbox && selectedFilterKey === filter.key">
              <CheckboxList
                class="mb-2 px-3 pb-1"
                :lazy="filter.lazy"
                :fetch-values="filter.fetchValues"
                :items="filter.items || []"
                :value="filter.value"
                :value-key="filter.itemValueKey"
                :search-item-key="filter.searchItemKey"
                :input-placeholder="filter.searchPlaceholder"
                :multi-select="filter.multiple"
                :searchable="filter.searchable"
                :scroll-height="filter.searchable || filter.searchable === undefined ? 415 : 450"
                :loading="loading"
                :label="filter.label"
                :sort-selected="filter.sortSelected"
                :description="filter.description"
                @update:value="(val) => handleFilterUpdate(filter, val)"
              >
                <!-- use CheckboxList content slot dynamically -->
                <template v-if="filter.renderCheckboxContent" #checkboxContent="{ item }">
                  <Component
                    :is="filter.renderCheckboxContent!(item)"
                    v-if="typeof filter.renderCheckboxContent!(item) === 'object'"
                  />
                  <span v-else>{{ filter.renderCheckboxContent!(item) }}</span>
                </template>

                <template #no-data>
                  {{ $t('common.no_filter_data') }}
                </template>
              </CheckboxList>
            </div>

            <!-- DATE ITEM -->
            <div v-else-if="filter.type === EFilterType.Date && selectedFilterKey === filter.key" class="p-3">
              <DatePickerPanel
                :loading="loading"
                :value="filter.value"
                @update:value="(val) => handleFilterUpdate(filter, val)"
              />
            </div>

            <!-- NUMBER RANGE -->
            <div v-else-if="filter.type === EFilterType.Range && selectedFilterKey === filter.key" class="p-3">
              <div class="font-500 mb-3 text-xs">{{ filter.label }}</div>
              <RangeSlider
                :lazy="filter.lazy"
                :fetch-values="filter.fetchValues"
                :value="filter.value || [filter.minimum, filter.maximum]"
                :max="filter.maximum"
                :min="filter.minimum"
                @update:value="(val) => handleFilterUpdate(filter, val)"
              />
            </div>

            <!-- TEXT ITEM -->
            <div v-else-if="filter.type === EFilterType.SearchInput && selectedFilterKey === filter.key" class="p-3">
              <div class="font-500 text-xs">{{ filter.label }}</div>
              <!-- we need to extract first element of the filter value
                because we are converting it to [string] on update -->
              <DebouncedInput
                :value="filter.value"
                class="mt-3"
                :placeholder="$t('common.search')"
                clearable
                :disabled="loading"
                @update:value="handleFilterUpdate(filter, $event)"
              >
                <template #prefix>
                  <NIcon>
                    <FaIcon icon="fa-search" size="xs" />
                  </NIcon>
                </template>
              </DebouncedInput>
            </div>

            <!-- CUSTOM ITEM -->
            <div v-else-if="filter.type === EFilterType.Custom && selectedFilterKey === filter.key" class="p-3">
              <div class="font-500 mb-3 text-xs">{{ filter.label }}</div>
              <Component :is="filter.component" v-bind="filter.props" />
            </div>
          </template>
          <slot
            v-if="selectedFilter"
            name="empty-filter"
            :filter="selectedFilter"
            @click="handleFilterUpdate(selectedFilter, undefined, true)"
          />
        </NLayout>
      </NLayout>

      <slot name="footer" />
    </div>

    <NLayout v-else class="bg-card-color b b-divider rounded-xl">
      <DataEmpty
        class="pa-4"
        message-class="text-sm mt-1!"
        title-class="text-sm mt-1"
        :message="$t('common.no_filter_data')"
        :show-clear-button="false"
      />
    </NLayout>
  </NPopover>
</template>

<script setup lang="ts">
import CheckboxList from '@/components/CheckboxList.vue'
import DataEmpty from '@/components/DataEmpty.vue'
import DatePickerPanel from '@/components/DatePickerPanel.vue'
import DebouncedInput from '@/components/DebouncedInput.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import SearchInput from '@/components/SearchInput.vue'
import TruncatedTooltip from '@/components/TruncatedTooltip.vue'
import { EFilterType, type TFilterConfig } from './'
import { computed, nextTick, ref, watch } from 'vue'
import { isEqual } from 'lodash-es'
import { useEventSubscription } from '@/composables/useEventBus'
import { useThemeStore } from '@/store'

interface IDropdownFilterProps {
  filtersConfig: Array<TFilterConfig>
  loading?: boolean
  filtersLoading?: boolean
  dropdownVisible?: boolean
  interactSelectedFilters?: boolean
  searchable?: boolean
}

const props = withDefaults(defineProps<IDropdownFilterProps>(), {
  interactSelectedFilters: true,
})

const emit = defineEmits<{
  'update:dropdownVisible': [value: boolean | undefined]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'update:filters': [key: any, value: any, label: any, isPlaceholder: boolean]
  'update:selectedFilter': [key: string]
}>()

const themeStore = useThemeStore()
const primaryColor = themeStore.themeVars.primaryColor

const selectedFilterKey = ref(props.filtersConfig[0]?.key)
const dropdownVisibleModel = ref(false)
const searchValue = ref('')

const selectedFilter = computed(() => props.filtersConfig.find((f) => f.key === selectedFilterKey.value))
const filteredFiltersConfig = computed(() => {
  if (!searchValue.value || !searchValue.value.length) return props.filtersConfig
  return props.filtersConfig.filter((f) => f.label.toLowerCase().includes(searchValue.value.toLowerCase()))
})

const handleFilterUpdate = (filter: TFilterConfig, value: Array<string | number> | undefined, isEmpty?: boolean) => {
  let newValue = value

  // if this is a range and selected value is equal to original min/max
  if (filter.type === 'range' && isEqual(value, [filter.minimum, filter.maximum])) {
    newValue = undefined
  }

  emit('update:filters', filter.key, newValue, undefined, !!isEmpty)
}

const handleDropdownVisibleUpdate = (val: boolean) => {
  dropdownVisibleModel.value = val
  emit('update:dropdownVisible', val)
}

const changeSelectedFilter = (key: string) => {
  selectedFilterKey.value = key
  emit('update:selectedFilter', key)
}

if (props.interactSelectedFilters) {
  useEventSubscription('selected-filter-click', (key: string) => {
    selectedFilterKey.value = key
    if (!dropdownVisibleModel.value) dropdownVisibleModel.value = true
  })
}

watch(
  () => props.dropdownVisible,
  (newVal) => nextTick(() => (dropdownVisibleModel.value = newVal)),
  {
    immediate: true,
  }
)

watch(
  () => props.filtersConfig,
  (newVal) => {
    if (newVal.length !== 0 && selectedFilterKey.value === undefined) {
      selectedFilterKey.value = newVal[0].key
    }
  }
)
</script>

<style lang="scss" scoped>
.filter-list {
  min-height: 250px;
  overflow-y: auto;

  &__item {
    &.selected,
    &:hover {
      background-color: var(--n-color-hover);
    }

    :deep(.n-button__content) {
      flex: 1;
    }
  }
}

.filter-content {
  min-width: 273px;
  max-width: 620px;
}
</style>
