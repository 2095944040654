/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromptModifier } from './PromptModifier';
export type TopicerParametersMore = {
  kind?: TopicerParametersMore.kind;
  within_category?: string;
  /**
   * Optional custom prompt to guide the topic generation process.
   */
  prompt?: string;
  /**
   * Optional modifier to guide the topic generation process.
   */
  prompt_modifier?: PromptModifier;
};
export namespace TopicerParametersMore {
  export enum kind {
    MORE = 'more',
  }
}

