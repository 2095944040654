/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type _AuxiliaryFieldRequest = {
  /**
   * Human-readable identifier for this column. When retrieving, filtering or uploading rows, a columns' reference is used to identify it. If not specified, a reference is automatically generated. The reference field is immutable and is unique among all columns within the same project.
   */
  ref?: string;
  /**
   * Human-readable name for this column.
   */
  name: string;
  /**
   * Columns of a project define its schema. In a sense, every [project column](https://caplena.com/docs/knowledge-base/ezyy60tig7uzy-data-structure-projects-text-to-analyze-rows-etc) corresponds to exactly one column in an Excel sheet. The four column types `numerical`, `date`, `boolean` and `text` are row columns, meaning that they won't be analyzed, but they can be used to visualize your results. Please note that for columns of type `numerical`, its integer values must be between `-(2^53-1)` and `2^53-1`. For bigger numbers, please use a column of type `text`.
   */
  type: _AuxiliaryFieldRequest.type;
};
export namespace _AuxiliaryFieldRequest {
  /**
   * Columns of a project define its schema. In a sense, every [project column](https://caplena.com/docs/knowledge-base/ezyy60tig7uzy-data-structure-projects-text-to-analyze-rows-etc) corresponds to exactly one column in an Excel sheet. The four column types `numerical`, `date`, `boolean` and `text` are row columns, meaning that they won't be analyzed, but they can be used to visualize your results. Please note that for columns of type `numerical`, its integer values must be between `-(2^53-1)` and `2^53-1`. For bigger numbers, please use a column of type `text`.
   */
  export enum type {
    NUMERICAL = 'numerical',
    DATE = 'date',
    BOOLEAN = 'boolean',
    TEXT = 'text',
  }
}

