import { type AnyObject, StringSchema, object, string } from 'yup'
import {
  AuxiliaryFieldUIResponse,
  type AvailableColumns,
  ColumnKind,
  type GenericInsightElementRequest,
  type GenericInsightElementResponse,
  InsightElementKind,
  type InsightElementsRequiredColumn,
  InsightElementsRequiredColumnIdentifier,
  type ReportResponseSchema,
  type SectionResponseSchema,
} from '@/api'
import { type ComputedRef, type Ref, computed, nextTick, onMounted, ref } from 'vue'
import { getTagColor } from '@/utils/color'
import { theme as uno } from '@unocss/preset-mini'
import { useScroll, watchDebounced } from '@vueuse/core'
import { useThemeStore } from '@/store'
import { useValidatedForm } from '@/plugins/form-validation'
import { values } from 'lodash-es'
import type { TIcon } from '@/plugins/font-awesome'

export type TInsightElementSelect = {
  id: string
  text: string
  icon: TIcon
  insight: GenericInsightElementResponse
  kind: GenericInsightElementRequest['kind']
}

export function useVisibleSection(sectionElements: Ref<Array<HTMLElement | null>>, containerId: string) {
  // explicit null is required since document.getElementByIt may return null
  const scrollingContent = ref<HTMLElement | null>(null)
  const scroll = useScroll(scrollingContent)
  const selectedSection = ref<string>()

  // we get scrolling content after DOM creation so that if user opens directly from report-detail it keeps working
  onMounted(() => {
    nextTick(() => {
      scrollingContent.value = document.getElementById(containerId)
      nextTick(setVisibleSection)
    })
  })

  function isSectionVisible(section: HTMLElement | null) {
    // offset for visibility to increase user experience,
    // otherwise selected section stays the same until it fully dissapears
    const visibilityOffset = 150

    if (!section) return false

    const top = section.offsetTop
    const height = section.offsetHeight

    return top + height - visibilityOffset > scroll.y.value
  }

  function setVisibleSection() {
    const visibleSections = sectionElements.value.filter(isSectionVisible).map((section) => section?.id)

    if (visibleSections[0]) {
      selectedSection.value = visibleSections[0]
    }
  }

  watchDebounced([() => scroll.y.value, () => sectionElements.value], setVisibleSection, { debounce: 50 })

  return selectedSection
}

const summaryInsights: string[] = [
  InsightElementKind.REPORT_SUMMARY_INSIGHT,
  InsightElementKind.REPORT_SUMMARY_NPSWO_TIME_INSIGHT,
  InsightElementKind.REPORT_SUMMARY_REVIEWS_INSIGHT,
  InsightElementKind.REPORT_SUMMARY_REVIEWS_WO_TIME_INSIGHT,
]

export function isSummarySection(section: SectionResponseSchema) {
  // summary section should contain one insight element by default (TODO: re-visit once backend is ready)
  if (!section.insight_elements) return false
  if (!section.insight_elements[0] || !section.insight_elements[0]?.kind) return false

  return section.insight_elements.length === 1 && summaryInsights.includes(section.insight_elements[0].kind)
}

export function useColumnTypeColors() {
  const theme = useThemeStore()

  return {
    [ColumnKind.DATE]: getTagColor(theme.themeVars.warningColor),
    [ColumnKind.TEXT]: getTagColor(theme.themeVars.primaryColor),
    [AuxiliaryFieldUIResponse.type.ANY]: getTagColor(theme.themeVars.errorColor),
    [ColumnKind.NULL]: getTagColor(theme.themeVars.errorColor),
    [ColumnKind.NUMERICAL]: getTagColor(theme.customVars.cSecondary),
    [ColumnKind.BOOLEAN]: getTagColor(uno.colors.fuchsia[500]),
    [ColumnKind.TEXT_TO_ANALYZE]: getTagColor(uno.colors.indigo[500]),
  }
}

export function useInsightElementColumnsForm(
  columns: ComputedRef<
    | {
        required: InsightElementsRequiredColumn[] | undefined
        available: AvailableColumns
      }
    | undefined
  >,
  dateTimeColumn?: string
) {
  const modelSchema = computed(() => {
    let schemaObject: Record<
      InsightElementsRequiredColumnIdentifier,
      StringSchema<string | undefined, AnyObject, undefined, ''>
    > = {
      nps_column: string(),
      rating_column: string(),
      text_column: string(),
      time_column: string(),
      tta_column: string(),
      breakout_column: string(),
      numerical_column: string(),
    }

    columns.value?.required?.forEach(({ req_class_attr_name }) => {
      if (!req_class_attr_name) {
        console.warn('Unknown column type', req_class_attr_name)
        return
      }

      schemaObject = {
        ...schemaObject,
        [req_class_attr_name]: (schemaObject[req_class_attr_name] || string()).required(),
      }
    })

    return object(schemaObject)
  })

  const { formFields, handleSubmit, isFormValid, resetForm } = useValidatedForm(modelSchema, {
    time_column: dateTimeColumn ?? '',
  })

  const getFormFieldAttr = (classAttrName?: InsightElementsRequiredColumnIdentifier) => {
    if (!classAttrName) throw new Error('Required Column Identifier is required')

    return formFields[classAttrName][1].value
  }

  const getFormFieldValue = (classAttrName?: InsightElementsRequiredColumnIdentifier) => {
    if (!classAttrName) return undefined

    return formFields[classAttrName][0].value
  }

  const updateFormFieldValue = (classAttrName?: InsightElementsRequiredColumnIdentifier, value?: string) => {
    if (!classAttrName) return

    formFields[classAttrName][0].value = value
  }

  return {
    getFormFieldValue,
    updateFormFieldValue,
    getFormFieldAttr,
    resetForm,
    handleSubmit,
    isFormValid,
  }
}

export function useReportSections(report: Ref<ReportResponseSchema | undefined>) {
  const reportSections = computed(() => values(report.value?.sections))
  const nonSummarySections = computed(() => reportSections.value.filter((s) => !isSummarySection(s)))
  const summarySection = computed(() => reportSections.value.find(isSummarySection))

  return {
    reportSections,
    nonSummarySections,
    summarySection,
  }
}
