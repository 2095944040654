<template>
  <div class="default-layout-container">
    <!-- Env bar  -->
    <div
      v-if="shouldShowEnvBar"
      class="h-4px fixed top-0 w-full"
      :style="`background: linear-gradient(90deg, #d53369, #daae51)`"
    />

    <CMessage
      v-if="shouldShowImpersonationBar"
      type="warning"
      class="top-12px fixed left-1/2 z-10 flex -translate-x-1/2"
      :show-icon="false"
    >
      {{ $t('common.impersonating') }}
      <span class="font-500">{{ auth.user?.name }} ({{ auth.user?.email }})</span>
    </CMessage>

    <!-- Sidebar -->
    <BaseSidebar v-model:collapsed="sidebarCollapsed" :items="menuItems">
      <template #pre>
        <RouterLink v-slot="{ navigate }" to="/projects">
          <div class="caplena-logo mb-8 cursor-pointer" @click="navigate">
            <span class="caplena-logo-content gap-2">
              <NImage :width="42" :src="caplenaLogo" preview-disabled />
              <Transition name="fade-slide">
                <span v-if="!sidebarCollapsed" class="c-header-text-color text-xl font-semibold">Caplena</span>
              </Transition>
            </span>
          </div>
        </RouterLink>
      </template>
    </BaseSidebar>

    <!-- allow teleporting secondary sidebar here -->
    <div id="secondary-sidebar" class="flex" />

    <!-- AppBar & Content -->
    <div class="default-layout-container__wrapper">
      <AppBar class="capp-header" />
      <div v-if="layoutMounted" id="capp-content" class="capp-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppBar from '@/components/common/AppBar.vue'
import BaseSidebar, { type TSidebarItem } from '@/components/common/BaseSidebar.vue'
import IntercomButton from '@/components/IntercomButton.vue'
import caplenaLogo from '@/assets/img/logo-black.svg'
import { computed, onMounted, ref } from 'vue'
import { isFeatureEnabled, itemsIfUserCanSeeDebugFeatures } from '@/plugins/posthog'
import { useAuthStore } from '@/store'
import { useTolgee } from '@tolgee/vue'

defineOptions({
  name: 'DefaultLayout',
})

const tolgee = useTolgee()
const auth = useAuthStore()
// TO DO: eventually to be enabled later
// const theme = useThemeStore() -> theme.customVars.logoUrl
const layoutMounted = ref(false)
const sidebarCollapsed = ref<boolean>(true)

const shouldShowImpersonationBar = computed(() => auth.user?.is_impersonated)
const shouldShowEnvBar = computed(() => import.meta.env.MODE !== 'production')

const menuItems = computed<TSidebarItem[]>(() => {
  const items: TSidebarItem[] = [
    {
      name: tolgee.value.t('navigation.projects'),
      to: '/projects',
      icon: 'fa-folder-closed',
    },
    ...itemsIfUserCanSeeDebugFeatures<TSidebarItem>([
      {
        name: 'Debug (Public)',
        to: '/debug',
        icon: 'fa-bug',
      },
    ]),
    // {
    //   type: 'toggler',
    // },
  ]

  const isIntercomEnabled = isFeatureEnabled('intercom')

  if (isIntercomEnabled) {
    items.push({
      type: 'custom',
      name: tolgee.value.t('navigation.message_us'),
      icon: 'far fa-comment-dots',
      component: IntercomButton,
      componentProps: {
        collapsed: sidebarCollapsed.value,
      },
    })
  }

  return items
})

onMounted(() => {
  layoutMounted.value = true
})
</script>

<style lang="scss" scoped>
@use '@/style/responsive.scss' as *;

.default-layout-container {
  --headerH: 60px;
  --wrapperMT: 4px;
  --wrapperMB: 4px;
  --wrapperMX: 4px;

  --contentPaddingT: 16px;
  --contentPaddingB: 16px;
  --contentPaddingX: 32px;

  height: 100%;
  display: flex;
  overflow: hidden;

  @include md-and-down {
    --contentPaddingX: 20px;
    // --contentPaddingB: 12px;
  }

  &__wrapper {
    position: relative;
    flex: 1;
    overflow-y: hidden;
    margin: var(--wrapperMT) var(--wrapperMX) var(--wrapperMB) 0;
    border: 1px solid var(--divider-color);
    transition: all var(--default-transition);
    @apply rounded-2xl;

    // size & positioning here only, to combine with content height
    .capp-header {
      height: var(--headerH);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 0 var(--contentPaddingX);
      background: var(--card-color);
    }

    .capp-content {
      margin-top: var(--headerH);
      // 100% -> (header + wrapper margin + content paddings)
      height: calc(
        100vh - (var(--headerH) + var(--wrapperMT) + var(--wrapperMB) + var(--contentPaddingT) + var(--contentPaddingB))
      );
      overflow-y: auto;
      scroll-behavior: smooth;
      border-radius: 0;
      padding: var(--contentPaddingT) var(--contentPaddingX) var(--contentPaddingB);
      @apply bg-card-color;
    }
  }
}

.caplena-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.caplena-logo-content {
  display: flex;
  align-items: center;
  min-width: 0;
}
</style>
