import BillingView from '@/views/billing'
import LoginView from '@/views/login'
import NotAuthorizedView from '@/views/_generic/not-authorized.view.vue'
import NotFoundView from '@/views/_generic/not-found.view.vue'
import ProjectsView from '@/views/projects'
import SettingsView from '@/views/settings'
import TeamView from '@/views/team'
import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { h } from 'vue'
import { queryClient } from '@/plugins/vue-query'
import { renderErrorDialogIcon } from '@/utils'
import { tolgee } from '@/plugins/i18n'
import { useAppStore, useAuthStore } from '@/store'

const routes: RouteRecordRaw[] = [
  // THIS IS NOT IN USE, IN ANY CASE WE REDIRECT TO PROJECTS
  // TO BE CONVERTED TO redirect: true WHEN WE REMOVE OLD APP
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/_generic/home.view.vue'),
    meta: {
      title: tolgee.t('page_titles.home'),
    },
  },
  {
    path: '/projects',
    children: [
      {
        path: '',
        name: 'projects',
        component: ProjectsView,
        meta: {
          title: tolgee.t('page_titles.projects'),
        },
      },
      {
        path: ':projectId',
        name: 'projectDetail',
        component: () => import('@/views/projects/project-detail.view.vue'),
        props: (route) => ({ projectId: route.params.projectId }),
        meta: {
          title: tolgee.t('page_titles.project_details'),
        },
        children: [
          {
            path: 'upload',
            name: 'projectDetail_upload',
            component: () => import('@/views/projects/upload.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
            meta: {
              title: tolgee.t('page_titles.upload'),
              hideProjectSidebar: true,
            },
          },
          {
            path: 'merge',
            name: 'projectDetail_merge',
            component: () => import('@/views/projects/upload.view.vue'),
            props: (route) => ({ projectId: route.params.projectId, merge: true }),
            meta: {
              title: tolgee.t('page_titles.merge'),
              hideProjectSidebar: true,
            },
          },
          {
            path: 'overview',
            name: 'projectDetail_overview',
            component: () => import('@/views/projects/overview.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
            meta: {
              title: tolgee.t('page_titles.overview'),
            },
          },
          {
            path: 'data',
            name: 'projectDetail_data',
            component: () => import('@/views/projects/data.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
            meta: {
              title: tolgee.t('page_titles.data'),
            },
          },
          {
            path: 'topics/:questionRef?',
            name: 'projectDetail_topics',
            component: () => import('@/views/projects/topics.view.vue'),
            props: (route) => ({ projectId: route.params.projectId, questionRef: route.params.questionRef }),
            meta: {
              title: tolgee.t('page_titles.topics'),
            },
          },
          {
            path: 'reports',
            name: 'projectDetail_reports',
            component: () => import('@/views/reports/reports.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
            meta: {
              title: tolgee.t('page_titles.reports'),
            },
          },
          {
            path: 'alerts',
            name: 'projectDetail_alerts',
            component: () => import('@/views/projects/alerts.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
            meta: {
              title: tolgee.t('page_titles.alerts'),
            },
          },
          {
            path: 'reports/:reportId',
            name: 'projectDetail_reportDetail',
            component: () => import('@/views/reports/report-detail.view.vue'),
            props: (route) => ({ projectId: route.params.projectId, reportId: route.params.reportId }),
            meta: {
              hideProjectSidebar: true,
              title: tolgee.t('page_titles.report_details'),
            },
          },
          {
            path: 'settings',
            name: 'projectDetail_settings',
            props: (route) => ({ projectId: route.params.projectId }),
            component: () => import('@/views/projects/settings.view.vue'),
            redirect: { name: 'projectDetail_settings_general' },
            children: [
              {
                path: '',
                name: 'projectDetail_settings_general',
                component: () => import('@/views/projects/settings/general.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
                meta: {
                  title: tolgee.t('page_titles.general_settings'),
                },
              },
              {
                path: 'permissions',
                name: 'projectDetail_settings_permissions',
                component: () => import('@/views/projects/settings/permissions.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
                meta: {
                  title: tolgee.t('page_titles.permissions'),
                },
              },
              {
                path: 'anonymization',
                name: 'projectDetail_settings_anonymization',
                component: () => import('@/views/projects/settings/anonymization.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
                meta: {
                  title: tolgee.t('page_titles.anonymization'),
                },
              },
              {
                path: 'data',
                name: 'projectDetail_settings_data',
                component: () => import('@/views/projects/settings/data.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
                meta: {
                  title: tolgee.t('page_titles.data'),
                },
              },
            ],
          },
          {
            path: 'insight-chat',
            name: 'projectDetail_insightChat',
            component: () => import('@/views/projects/insight-chat.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
            meta: {
              title: tolgee.t('page_titles.insight_chat'),
            },
          },
          {
            path: '',
            name: 'projectDetail_redirect',
            redirect: (to) => ({ params: to.params, name: 'projectDetail_overview' }),
          },
        ],
      },
    ],
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView,
    meta: {
      title: tolgee.t('page_titles.team'),
    },
  },
  {
    path: '/billing',
    name: 'billing',
    component: BillingView,
    meta: {
      title: tolgee.t('page_titles.billing'),
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    children: [
      {
        path: '',
        name: 'settings_account',
        component: () => import('@/views/settings/account.view.vue'),
        meta: {
          title: tolgee.t('page_titles.account'),
        },
      },
      {
        path: 'organization',
        name: 'settings_organization',
        component: () => import('@/views/settings/organization.view.vue'),
        meta: {
          title: tolgee.t('page_titles.organization'),
        },
      },
      {
        path: 'theme',
        name: 'settings_theme',
        component: () => import('@/views/settings/theme.view.vue'),
        meta: {
          title: tolgee.t('page_titles.theme'),
        },
      },
      {
        path: 'api',
        name: 'settings_api',
        component: () => import('@/views/settings/api.view.vue'),
        meta: {
          title: tolgee.t('page_titles.api'),
        },
      },
      {
        path: 'integrations',
        name: 'settings_integrations',
        component: () => import('@/views/settings/integrations.view.vue'),
        meta: {
          title: tolgee.t('page_titles.integrations'),
        },
      },
      {
        path: 'security',
        name: 'settings_security',
        component: () => import('@/views/settings/security.view.vue'),
        meta: {
          title: tolgee.t('page_titles.security'),
        },
      },
    ],
  },
  {
    path: '/debug',
    name: 'debug',
    component: () => import('@/views/debug'),
    meta: {
      allowAnonymous: true,
      title: 'Debug',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      allowAnonymous: true,
      layout: undefined,
      title: tolgee.t('page_titles.login'),
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      async beforeRouteEnter(to, from, next) {
        const auth = useAuthStore()

        queryClient.cancelQueries()
        await auth.logout()
        next('/login')
      },
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/_generic/maintenance.view.vue'),
    beforeEnter: (to, from, next) => {
      if (!useAppStore().maintenance.active) next('/')
      else next()
    },
    meta: {
      allowAnonymous: true,
      layout: undefined,
      title: tolgee.t('page_titles.maintenance'),
    },
  },
  {
    path: '/reporting/report-views/:reportViewId/:projectId',
    name: 'sharedReportView',
    component: () => import('@/views/reports/shared-report-view.vue'),
    props: (route) => ({ reportViewId: route.params.reportViewId }),
    meta: {
      allowAnonymous: true,
      layout: 'SharedLayout',
      title: tolgee.t('page_titles.shared_report_view'),
    },
    children: [
      {
        path: ':reportId',
        name: 'sharedReportView_reportDetail',
        component: () => import('@/views/reports/report-detail.view.vue'),
        props: (route) => ({
          projectId: route.params.projectId,
          reportId: route.params.reportId,
          viewOnly: true,
        }),
        meta: {
          title: tolgee.t('page_titles.report_details'),
        },
      },
    ],
  },
  { path: '/not-authorized', name: '404', component: NotAuthorizedView },
  { path: '/:pathMatch(.*)*', name: '403', component: NotFoundView },
]

// add default route meta
routes.forEach((route) => {
  if (!route.meta) route.meta = {}
  if (!('layout' in route.meta)) route.meta.layout = 'DefaultLayout'
  if (!route.meta.allowAnonymous) route.meta.allowAnonymous = false
})

// create router
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// router navigation guards
router.beforeEach(async (to, from, next) => {
  const app = useAppStore()
  const auth = useAuthStore()
  const isPublic = to.meta?.allowAnonymous

  // we are closing all dialogs before navigating to a new route
  if (window.$dialog) window.$dialog.destroyAll()

  if (!navigator.onLine) {
    window.$dialog.error({
      icon: () => renderErrorDialogIcon('far fa-wifi-exclamation'),
      title: tolgee.t('generic_views.connection_issue_title'),
      content: () =>
        h('div', {
          innerHTML: tolgee.t('generic_views.connection_issue'),
        }),
      positiveText: tolgee.t('common.close'),
    })
  }

  if (!app.isInitialized) {
    await app.initialize(isPublic)
  }

  const authGuard = !isPublic && !auth.isLoggedIn
  const maintenanceGuard = !isPublic && app.maintenance.active && to.name !== 'maintenance'
  const legacyRedirect = 'legacy' in to.query

  // check authentication
  if (authGuard) {
    // TO DO: we will need to check/test this part when we remove the old app from the logic
    const login = { name: 'login', query: to.query ?? {} }

    if (to.path !== '/') {
      login.query = { go_to: to.fullPath }
    }

    next(login)
    return
    // check if maintenance is active
  } else if (maintenanceGuard) {
    next({ name: 'maintenance' })
    return
  } else if (auth.isLoggedIn && legacyRedirect) {
    // LEGACY REDIRECT IN PRODUCTION WITH QUERY PARAM(TO BE REMOVED WHEN OLD APP IS REMOVED)
    if (import.meta.env.PROD && import.meta.env.MODE === 'production') {
      window.location.href = `https://caplena.com${to.query.go_to ? to.query.go_to : '/app'}`
    }
    return
    // }
  } else if (to.path === '/') {
    // trying to access home, redirect to projects by default
    // NOTE: DO NOT CHANGE THIS IN HOME ROUTE, THIS IS ON PURPOSE (until we remove old app)
    next({ name: 'projects' })
    return
  }

  // all good, go to next page
  next()
})

// Add title navigation guard after router creation
router.beforeEach((to) => {
  const baseTitle = 'Caplena'
  // we need to use tolgee.t here since it's not translated in the routers array
  const pageTitle = tolgee.t(to.meta?.title ?? '')

  document.title = pageTitle ? `${pageTitle} | ${baseTitle}` : baseTitle
})

export default router
