import {
  CodeSentiments,
  type DataPoint,
  type NestedCategorySegmentTopicCount,
  type NestedCategoryTopicBucketCount,
  type NestedCategoryTopicCount,
  ProjectCodingRowTextUIResponse,
  type TopicCount,
  TopicFilterSentiments,
  TopicSentimentsInResults,
  type Values,
} from '@/api'
import { colord } from 'colord'
import { formatNumber } from '@/plugins/i18n'
import { ref } from 'vue'
import { theme as uno } from '@unocss/preset-mini'
import { useThemeStore } from '@/store'
import type { EChartsOption, TreemapSeriesOption } from 'echarts'
import type { TIcon } from '@/plugins/font-awesome'
import type { XAXisOption, YAXisOption } from 'echarts/types/dist/shared.js'

export const DEFAULT_BAR_WIDTH = 14
export const DEFAULT_BAR_RADIUS = 5

// TO DO: to be deleted
export const dummySelectItems = ref([
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Top 3',
    value: 'top3',
  },
  {
    label: 'Top 5',
    value: 'top5',
  },
  {
    label: 'Top 10',
    value: 'top10',
  },
])

export type TSentimentMapper = Record<
  TopicSentimentsInResults,
  {
    icon: TIcon
    titleIcon: TIcon
    color: string
    background: string
    line: string
  }
>

export const useSentimentMap = (): TSentimentMapper & {
  getSentimentInformation: (
    sentiment?:
      | TopicSentimentsInResults
      | null
      | ProjectCodingRowTextUIResponse.sentiment_overall
      | TopicFilterSentiments
      | CodeSentiments
  ) => {
    icon: TIcon
    titleIcon: TIcon
    color: string
    background: string
  }
} => {
  const theme = useThemeStore()
  const sentimentMap: TSentimentMapper = {
    [TopicSentimentsInResults.POSITIVE]: {
      icon: 'far fa-smile',
      titleIcon: 'far fa-link-horizontal',
      color: theme.customVars.chart.positiveDarker,
      background: colord(theme.customVars.chart.positive).darken(0.1).alpha(0.1).toHex(),
      line: theme.customVars.chart.positiveLine,
    },
    [TopicSentimentsInResults.NEGATIVE]: {
      icon: 'far fa-frown',
      titleIcon: 'far fa-link-horizontal-slash',
      color: theme.customVars.chart.negativeDarker,
      background: colord(theme.customVars.chart.negative).darken(0.1).alpha(0.1).toHex(),
      line: theme.customVars.chart.negativeLine,
    },
    [TopicSentimentsInResults.NEUTRAL]: {
      icon: 'far fa-meh',
      titleIcon: 'far fa-link-horizontal',
      color: theme.customVars.cGray[600],
      background: colord(theme.customVars.cGray[600]).darken(0.1).alpha(0.1).toHex(),
      line: theme.customVars.chart.neutralLine,
    },
    [TopicSentimentsInResults.ANY]: {
      icon: 'far fa-meh',
      titleIcon: 'far fa-link-horizontal',
      color: theme.customVars.cGray[600],
      background: colord(theme.customVars.cGray[600]).darken(0.1).alpha(0.1).toHex(),
      line: theme.customVars.chart.neutralLine,
    },
    [TopicSentimentsInResults.NONE]: {
      icon: 'far fa-meh',
      titleIcon: 'far fa-link-horizontal',
      color: theme.customVars.cGray[600],
      background: colord(theme.customVars.cGray[600]).darken(0.1).alpha(0.1).toHex(),
      line: theme.customVars.chart.neutralLine,
    },
  }

  const getSentimentInformation = (
    sentiment?:
      | TopicSentimentsInResults
      | null
      | ProjectCodingRowTextUIResponse.sentiment_overall
      | TopicFilterSentiments
      | CodeSentiments
  ): {
    icon: TIcon
    titleIcon: TIcon
    color: string
    background: string
  } => {
    if (!sentiment) return sentimentMap[TopicSentimentsInResults.NEUTRAL]

    return sentimentMap[sentiment]
  }

  return {
    ...sentimentMap,
    getSentimentInformation,
  }
}

export type TNestedChartDatasetItem = {
  id: string
  category: string
  color?: string
  originalLabel: string
  formattedLabel: string
  isCategory: boolean
  // to keep this object accepting other types and not conflicting with the defined props
  [key: string]: number | string | boolean | undefined
}

export const getTooltipSerieItem = (
  label: string,
  value: string,
  color?: string,
  options: {
    valueBold?: boolean
    valueColor?: boolean
  } = { valueBold: true, valueColor: false }
) => `
<div class="flex items-center">
  ${
    color && !options.valueColor
      ? `<span style="background: ${color}; height: 8px; width: 8px; border-radius: 50%; margin-right: 6px"></span>`
      : ''
  }
  <span>${label}</span>
  &nbsp;
  <span
    class="${options.valueBold ? 'font-semibold' : ''} ml-auto"
    style="color: ${options.valueColor ? color : 'black'}"
  >
    ${value || '-'}
  </span>
</div>`

export const parseNestedDataToTreemap = (data: NestedCategoryTopicCount[]): TreemapSeriesOption['data'] => {
  const parseData = (
    item: NestedCategoryTopicCount | TopicCount,
    isCategory = true,
    categoryName: string = ''
  ): NonNullable<TreemapSeriesOption['data']>[number] & {
    value_relative?: number
    categoryName?: string
  } => {
    // there is no color information defined on category, so we take the color from its topics (as all topics of the same category have the same color)
    const color = isCategory ? (item as NestedCategoryTopicCount).topics[0]?.color : (item as TopicCount).color

    return {
      id: item.id,
      name: item.label,
      value: item.value,
      value_relative: item.value_relative,
      categoryName: isCategory ? item.label : categoryName,
      itemStyle: {
        color,
      },
      children: !isCategory
        ? undefined
        : (item as NestedCategoryTopicCount).topics.map((t) => parseData(t, false, item.label)),
    }
  }

  return data.map((cat) => parseData(cat))
}

export const getCommonTreemapOptions = (data: TreemapSeriesOption['data']): EChartsOption => {
  const themeStore = useThemeStore()

  return {
    tooltip: {
      // there is a bug with the parameter type, it is not declared properly in echarts
      // https://github.com/apache/echarts/issues/14277
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formatter(params: any) {
        if (params.dataIndex === 0) return ''

        const percentage = formatNumber(params.data.value_relative * 100)
        const value = `${params.data.value} (${percentage}%)`
        const name = `<span class="font-semibold">${params.data.name}</span>`

        return `${getTooltipSerieItem(name, value, params.color, { valueBold: true })}`
      },
    },
    series: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      type: 'treemap',
      visibleMin: 300,
      roam: false,
      nodeClick: false,
      label: {
        show: true,
        color: themeStore.themeVars.textColor1,
      },
      upperLabel: {
        show: true,
        color: themeStore.themeVars.textColor1,
        fontWeight: 500,
        height: 30,
        padding: [0, 0, 0, 8],
      },
      breadcrumb: {
        show: false,
      },
      levels: [
        {
          itemStyle: {
            borderWidth: 0,
            gapWidth: 4,
            borderColor: 'transparent',
          },
          upperLabel: {
            show: false,
          },
        },
        {
          itemStyle: {
            gapWidth: 4,
            borderColorSaturation: 0.6,
            colorAlpha: 0.3,
            borderWidth: 4,
            borderRadius: 8,
          },
          upperLabel: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: (val: any) => val.name.toUpperCase(),
          },
        },
        {
          itemStyle: {
            borderColorSaturation: 0.7,
            borderRadius: 4,
          },
        },
      ],
      data,
    },
  }
}

export type TSegmentedBarTransformedItem = {
  id: string
  label: string
  color?: string
  topics?: Array<TSegmentedBarTransformedItem>
  parent?: string
  values: {
    [key: string]: {
      value?: number
      value_relative?: number
      count?: number
      isCategory?: boolean
    }
  }
}

// used mainly in topics overview -> segmented bar chart
export const transformNestedCategorySentimentData = (
  res: Record<string, NestedCategoryTopicCount[]>,
  segmentKeys: string[]
) => {
  const sortItems = (items: TSegmentedBarTransformedItem[]) => {
    return items.sort((a, b) => {
      const aSum = segmentKeys.reduce((sum, key) => (a.values[key]?.value || 0) + sum, 0)
      const bSum = segmentKeys.reduce((sum, key) => (b.values[key]?.value || 0) + sum, 0)

      return aSum - bSum
    })
  }

  const segmentedItemsDictionary = segmentKeys.reduce<
    Record<string, TSegmentedBarTransformedItem & { topicsMap?: Map<string, TSegmentedBarTransformedItem> }>
  >((acc, segmentId) => {
    const categories = res[segmentId] || []

    categories.forEach((cat) => {
      if (!acc[cat.id]) {
        acc[cat.id] = {
          id: cat.id,
          label: cat.label,
          topics: [],
          topicsMap: new Map<string, TSegmentedBarTransformedItem>(),
          values: {},
          color: cat.topics[0]?.color,
        }
      }

      acc[cat.id].values[segmentId] = {
        value: cat.value_relative,
        count: cat.value,
        isCategory: true,
      }

      cat.topics.forEach((topic) => {
        let topicFound = acc[cat.id].topicsMap!.get(topic.id)

        if (!topicFound) {
          topicFound = {
            id: topic.id,
            label: topic.label,
            parent: cat.id,
            color: topic.color,
            values: {
              [segmentId]: {
                value: topic.value_relative,
                count: topic.value,
              },
            },
          }
          acc[cat.id].topicsMap!.set(topic.id, topicFound)
        } else {
          topicFound.values[segmentId] = {
            value: topic.value_relative,
            count: topic.value,
          }
        }
      })

      acc[cat.id].topics = Array.from(acc[cat.id].topicsMap!.values())
    })

    return acc
  }, {})

  const result = Object.values(segmentedItemsDictionary)

  result.forEach((cat) => {
    if (cat.topics) {
      sortItems(cat.topics)
    }
  })

  return sortItems(result)
}

// used mainly in topic sentiment -> segmented/nested bar chart
export const transformCategoryTopicsSegmentValuesData = (
  res: Record<string, NestedCategorySegmentTopicCount[]>,
  selectedSegmentId: string,
  selectedSentiment: `${TopicSentimentsInResults}`
): TSegmentedBarTransformedItem[] => {
  const sortItems = (items: TSegmentedBarTransformedItem[]) => {
    /**
     * summarize a & b enabled sentiment attributes
     * e.g. if only `positive` sentiment is enabled, sort depending on positive values
     * if all sentiments are enabled, summarize negative, positive and neutral, then sort depending on total value
     */
    const sorted = items.sort((a, b) => {
      const sentiments = Object.keys(a.values)
      const aSum = sentiments.reduce((sum, key) => (a.values[key]?.value || 0) + sum, 0)
      const bSum = sentiments.reduce((sum, key) => (b.values[key]?.value || 0) + sum, 0)

      return aSum - bSum
    })

    return sorted
  }

  const transformCategoryValues = (
    categoryValues: Record<string, Values>,
    sentiment: string,
    category: NestedCategorySegmentTopicCount,
    itemMap: Map<string, TSegmentedBarTransformedItem & { topicsMap?: Map<string, TSegmentedBarTransformedItem> }>
  ) => {
    const sentimentValues = categoryValues[sentiment]

    let cat = itemMap.get(category.id)

    if (!cat) {
      cat = {
        id: category.id,
        label: category.label,
        topicsMap: new Map<string, TSegmentedBarTransformedItem>(),
        values: {},
      }
      itemMap.set(category.id, cat)
    }

    cat.values[sentiment] = {
      value: sentimentValues?.value_relative,
      count: sentimentValues?.value,
    }

    category.topics.forEach((topic) => {
      let topicFound = cat!.topicsMap!.get(topic.id)

      if (!topicFound) {
        topicFound = {
          id: topic.id,
          label: topic.label,
          parent: category.id,
          values: {
            [sentiment]: {
              value: topic.values[selectedSegmentId]?.values[sentiment]?.value_relative,
              count: topic.values[selectedSegmentId]?.values[sentiment]?.value,
            },
          },
        }
        cat!.topicsMap!.set(topic.id, topicFound)
      } else {
        topicFound.values[sentiment] = {
          value: topic.values[selectedSegmentId]?.values[sentiment]?.value_relative,
          count: topic.values[selectedSegmentId]?.values[sentiment]?.value,
        }
      }
    })

    cat.topics = cat.topicsMap ? Array.from(cat.topicsMap.values()) : undefined
  }

  const sentimentedResult = res[selectedSentiment]
  const itemMap = new Map<string, TSegmentedBarTransformedItem>()

  sentimentedResult.forEach((category) => {
    const categoryValues = category.values[selectedSegmentId].values

    if (selectedSentiment === TopicSentimentsInResults.ANY) {
      const sentiments = Object.keys(categoryValues)

      sentiments.forEach((sentiment) => {
        transformCategoryValues(categoryValues, sentiment, category, itemMap)
      })
    } else {
      transformCategoryValues(categoryValues, selectedSentiment, category, itemMap)
    }
  })

  const result = Array.from(itemMap.values())

  result.forEach((cat) => {
    if (cat.topics) {
      cat.topics = sortItems(cat.topics)
    }
  })

  return sortItems(result)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tooltipFormatter = (params: any) => {
  if (!params) return ''

  const getRelativeVal = (val: unknown) => (typeof val === 'number' ? formatNumber(val * 100) : '-')

  // ensure params is an object (single params item)
  if (!Array.isArray(params)) {
    const values = `${params.data.value} (${getRelativeVal(params.data.value_relative)}%)`
    const name = `<span class="font-semibold">${params.data.name}</span>`

    return `${getTooltipSerieItem(name, values, params.color, { valueBold: false })}`
  }

  return ''
}

// there is a bug with the parameter type, it is not declared properly in echarts
// https://github.com/apache/echarts/issues/14277
export const segmentedTooltipFormatter = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
  getTooltipSerieOptions: (index: number) => {
    label: string
    value: string
    color?: string
    options?: {
      valueBold?: boolean
      valueColor?: boolean
    }
  }[],
  getLabel: (label: string) => string = (label: string) => label
) => {
  const theme = useThemeStore()

  if (!params) return ''
  const label = getLabel(params[0].axisValue)

  return `
          <div class="px-1">
            <div class="flex items-center mb-1 text-xs">
              <span class="mr-15 font-semibold">${label}</span>
            </div>
            ${params
              .map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (segment: any, index: number) => {
                  return `
                    <div class="b-t-1 w-full my-1.5" style="border-color: ${theme.customVars.chart.neutralLight}"></div>
                    <div class="mr-12 c-black">
                      ${getTooltipSerieItem(segment.seriesName, '', segment.color)}
                    </div>
                    ${getTooltipSerieOptions(index)
                      .map((item) => getTooltipSerieItem(item.label, item.value, item.color, item.options))
                      .join('')}
                  `
                }
              )
              .join('')}
          </div>
        `
}

// TO DO: temporary function to transform data - backend will merge two data types into one so that we will use transformCategoryTopicsSegmentValuesData instead
export const transformNestedBucketCategoryTopicsValues = (
  res: Record<string, NestedCategoryTopicBucketCount[]>,
  selectedSentiment: string = 'any'
): TSegmentedBarTransformedItem[] => {
  const sortItems = (items: TSegmentedBarTransformedItem[]) => {
    /**
     * summarize a & b enabled sentiment attributes
     * e.g. if only `positive` sentiment is enabled, sort depending on positive values
     * if all sentiments are enabled, summarize negative, positive and neutral, then sort depending on total value
     */
    const sorted = items.sort((a, b) => {
      const sentiments = Object.keys(a.values)
      const aSum = sentiments.reduce((sum, key) => (a.values[key]?.value || 0) + sum, 0)
      const bSum = sentiments.reduce((sum, key) => (b.values[key]?.value || 0) + sum, 0)

      return aSum - bSum
    })

    return sorted
  }

  const transformCategoryValues = (
    categoryValues: Record<string, DataPoint>,
    sentiment: string,
    category: NestedCategoryTopicBucketCount,
    itemMap: Map<string, TSegmentedBarTransformedItem & { topicsMap?: Map<string, TSegmentedBarTransformedItem> }>
  ) => {
    const sentimentValues = categoryValues[sentiment]

    let cat = itemMap.get(category.id)

    if (!cat) {
      cat = {
        id: category.id,
        label: category.label,
        topicsMap: new Map<string, TSegmentedBarTransformedItem>(),
        values: {},
      }
      itemMap.set(category.id, cat)
    }

    cat.values[sentiment] = {
      count: sentimentValues?.row_count,
      value: sentimentValues?.row_count_relative,
      isCategory: true,
    }

    category.topics.forEach((topic) => {
      let topicFound = cat!.topicsMap!.get(topic.id)

      if (!topicFound) {
        topicFound = {
          id: topic.id,
          label: topic.label,
          parent: category.id,
          values: {
            [sentiment]: {
              count: topic.values[sentiment]?.row_count,
              value: topic.values[sentiment]?.row_count_relative,
            },
          },
        }
        cat!.topicsMap!.set(topic.id, topicFound)
      } else {
        topicFound.values[sentiment] = {
          count: topic.values[sentiment]?.row_count,
          value: topic.values[sentiment]?.row_count_relative,
        }
      }
    })

    cat.topics = cat.topicsMap ? Array.from(cat.topicsMap.values()) : undefined
  }

  const sentimentedResult = res[selectedSentiment]
  const itemMap = new Map<string, TSegmentedBarTransformedItem>()

  sentimentedResult.forEach((category) => {
    const categoryValues = category.values

    if (selectedSentiment === TopicSentimentsInResults.ANY) {
      const sentiments = Object.keys(categoryValues)

      sentiments.forEach((sentiment) => {
        transformCategoryValues(categoryValues, sentiment, category, itemMap)
      })
    } else {
      transformCategoryValues(categoryValues, selectedSentiment, category, itemMap)
    }
  })

  const result = Array.from(itemMap.values())

  result.forEach((cat) => {
    if (cat.topics) {
      cat.topics = sortItems(cat.topics)
    }
  })

  return sortItems(result)
}

// Sentiment related helpers
type TSentiments = 'positive' | 'negative' | 'neutral'

export const getSentimentSmileyURL = (sentiment: TSentiments) => {
  return new URL(`../../../assets/img/sentiment-${sentiment}-smiley.svg`, import.meta.url).href
}

export const sentimentAxisLabel: YAXisOption['axisLabel'] | XAXisOption['axisLabel'] = {
  formatter(value: number | string) {
    if (typeof value === 'string') return `{${value}|}`

    // we take -95 as negative, 0 as neutral and 95 as positive. we want to have some margins on the axis that's why we don't use 100 and -100
    // other values should be ignored

    if (value === -95) return '{negative|}'
    if (value === 0) return '{neutral|}'
    if (value === 95) return '{positive|}'

    return ''
  },
  show: true,
  fontFamily: 'Inter',
  color: uno.colors.gray[400],
  rich: {
    positive: {
      height: 25,
      backgroundColor: {
        image: getSentimentSmileyURL('positive'),
      },
    },
    neutral: {
      height: 25,
      backgroundColor: {
        image: getSentimentSmileyURL('neutral'),
      },
    },
    negative: {
      height: 25,
      backgroundColor: {
        image: getSentimentSmileyURL('negative'),
      },
    },
  },
}
