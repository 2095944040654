import { theme as uno } from '@unocss/preset-mini'
import type { GlobalThemeOverrides } from 'naive-ui'

import { colord } from 'colord'

export const breakpoints = { xs: 0, s: 640, m: 1024, l: 1280, xl: 1536, xxl: 1920 }

const primary = '#1153D4'
const error = {
  50: '#FEF5F2',
  100: '#FEE7E2',
  200: '#FED3CA',
  300: '#FF9982',
  400: '#F97850',
  500: '#F05938',
  600: '#D94220',
  700: '#B43418',
  800: '#912E18',
  900: '#7B1F0B',
}
const success = {
  50: '#ECFDF8',
  100: '#DCFAF1',
  200: '#7CE8C6',
  300: '#5CD2B4',
  400: '#47CDA5',
  500: '#17B28D',
  600: '#079472',
  700: '#06765B',
  800: '#085D4E',
  900: '#074D3C',
}
const warning = {
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FFEE97',
  300: '#FFD15C',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
}
const gray = {
  50: '#FDFDFC',
  100: '#F8F6F3',
  200: '#F4F1ED',
  300: '#F2EEE8',
  400: '#DFD8CF',
  500: '#C6C1B8',
  600: '#8E8989',
  700: '#787272',
  800: '#746B6B',
  900: '#3A342D',
}

// NOTE: Anything you add here will be declared as a CSS variable on the global scope
// Also, the Caplena color variables defined here are used as CSS variables in unocss.config.js
// Variables here SHOULD NOT conflict with Naive-UI variables
export const sharedCustomVars: TCustomThemeVars = {
  logoUrl: undefined,
  cBase: '#F8F6F3',
  cSecondary: '#00C58D',
  cPurple: '#7B287D',
  cPurpleDark: '#330C2F',
  cIndigo: '#7067CF',
  cIndigoLight: '#B7C0EE',
  cRed: '#FF5151',
  cGreenLight: '#CBF3D2',
  cBlueLight: '#9FC0FD',
  cGray: gray,

  cBlue: {
    25: '#FDFDFE',
    50: '#F6FAFF',
    100: '#F6FAFF',
    200: '#DDEAFF',
    300: '#CCE0FF',
    400: '#B7D3FF',
    500: '#B7D3FF',
    600: '#7DA7F4',
    700: '#245DCE',
    800: '#1153D4',
    900: '#0042C3',
    950: '#152E5F',
  },

  chart: {
    primary: uno.colors.blue[700],
    secondary: uno.colors.yellow[500],
    tertiary: uno.colors.indigo[500],

    negative: error[300],
    negativeDarker: error[400],
    negativeLine: error[500],

    neutralLight: gray[200],

    neutral: gray[300],
    neutralDarker: gray[400],
    neutralLine: gray[600],

    passive: warning[200],
    passiveDarker: warning[300],
    passiveLine: warning[500],

    positive: success[200],
    positiveDarker: success[300],
    positiveLine: success[500],

    warningColor: warning[400],
  },

  defaultTransition: '0.2s cubic-bezier(0.2, 0, 0.1, 1)',
}

const info = sharedCustomVars.cGray[800]

export const sharedOverrides: GlobalThemeOverrides = {
  common: {
    bodyColor: sharedCustomVars.cGray[100],
    primaryColor: primary,
    primaryColorHover: colord(primary).darken(0.05).toHex(),
    primaryColorPressed: primary,
    primaryColorSuppl: primary,
    infoColor: info,
    infoColorHover: colord(info).darken(0.05).toHex(),
    infoColorPressed: info,
    infoColorSuppl: info,
    errorColor: error[500],
    errorColorHover: colord(error[500]).darken(0.05).toHex(),
    errorColorPressed: error[500],
    errorColorSuppl: error[500],
    successColor: success[500],
    successColorHover: colord(success[500]).darken(0.05).toHex(),
    successColorPressed: success[500],
    successColorSuppl: success[500],
    warningColor: warning[500],
    warningColorHover: colord(warning[500]).darken(0.05).toHex(),
    warningColorPressed: warning[500],
    warningColorSuppl: warning[500],
    textColor1: sharedCustomVars.cGray[900],
    textColor2: sharedCustomVars.cGray[800],
    textColor3: sharedCustomVars.cGray[600],
    textColorBase: '#000',

    hoverColor: sharedCustomVars.cGray[200],
    dividerColor: sharedCustomVars.cGray[300],
    fontFamily: 'Inter, sans-serif',
    fontWeightStrong: '700',
    cubicBezierEaseInOut: 'cubic-bezier(0.2, 0, 0.1, 1)',
    cubicBezierEaseOut: 'cubic-bezier(0, 0, 0.2, 1)',
    cubicBezierEaseIn: 'cubic-bezier(0.2, 0, 0.1, 1)',
    borderRadius: '8px',
    borderRadiusSmall: '8px',

    cardColor: '#fff',
  },
  Alert: {
    titleFontWeight: '500',
    // type: info
    colorInfo: sharedCustomVars.cBlue[50],
    borderInfo: `1px solid ${sharedCustomVars.cBlue[500]}`,
    iconColorInfo: sharedCustomVars.cBlue[900],
    closeIconColorInfo: sharedCustomVars.cBlue[900],
    titleTextColorInfo: sharedCustomVars.cBlue[900],
    contentTextColorInfo: sharedCustomVars.cBlue[900],
    closeIconColorHoverInfo: sharedCustomVars.cBlue[800],
    closeIconColorPressedInfo: sharedCustomVars.cBlue[800],
    closeColorHoverInfo: sharedCustomVars.cBlue[200],
    closeColorPressedInfo: sharedCustomVars.cBlue[300],
    colorSuccess: success[50],
    // type: success
    borderSuccess: `1px solid ${success[400]}`,
    iconColorSuccess: success[700],
    closeIconColorSuccess: success[700],
    titleTextColorSuccess: success[700],
    contentTextColorSuccess: success[700],
    closeIconColorHoverSuccess: success[800],
    closeIconColorPressedSuccess: success[800],
    closeColorHoverSuccess: success[100],
    closeColorPressedSuccess: success[200],
    // type: error
    colorError: error[50],
    borderError: `1px solid ${error[400]}`,
    iconColorError: error[700],
    closeIconColorError: error[700],
    titleTextColorError: error[700],
    contentTextColorError: error[700],
    closeIconColorHoverError: error[800],
    closeIconColorPressedError: error[800],
    closeColorHoverError: error[100],
    closeColorPressedError: error[200],
    // type: warning
    colorWarning: warning[50],
    borderWarning: `1px solid ${warning[400]}`,
    iconColorWarning: warning[700],
    closeIconColorWarning: warning[700],
    titleTextColorWarning: warning[700],
    contentTextColorWarning: warning[700],
    closeIconColorHoverWarning: warning[800],
    closeIconColorPressedWarning: warning[800],
    closeColorHoverWarning: warning[100],
    closeColorPressedWarning: warning[200],
  },
  DataTable: {
    borderRadius: '12px',
    thPaddingMedium: '12px 16px',
    tdPaddingMedium: '12px 16px',
  },
  Button: {
    rippleDuration: '0',
    colorFocusPrimary: 'var(--primary-color)',
    colorFocusInfo: 'var(--info-color)',
    colorFocusError: 'var(--error-color)',
    colorFocusSuccess: 'var(--success-color)',
    colorFocusWarning: 'var(--warning-color)',
    colorOpacitySecondary: 0.06,
    colorOpacitySecondaryHover: 0.14,
    colorOpacitySecondaryPressed: 0.22,
    heightTiny: '24px',
    heightSmall: '28px',
    heightMedium: '32px',
    heightLarge: '40px',
    fontSizeSmall: '12px',
    fontWeight: '500',
    colorSecondary: sharedCustomVars.cGray[100],
    colorSecondaryHover: sharedCustomVars.cGray[200],
    colorSecondaryPressed: sharedCustomVars.cGray[300],
    colorTertiary: sharedCustomVars.cGray[100],
    colorTertiaryHover: sharedCustomVars.cGray[200],
    colorTertiaryPressed: sharedCustomVars.cGray[300],
    colorQuaternaryHover: sharedCustomVars.cGray[200],
    colorQuaternaryPressed: sharedCustomVars.cGray[300],
    textColorSecondary: sharedCustomVars.cGray[400],
  },
  Input: {
    boxShadowHover: '0px 0px 2px 0px rgba(125, 167, 244, 1)',
    boxShadowFocus: '0px 0px 2px 0px rgba(125, 167, 244, 1)',
    border: '1px solid var(--c-gray-400)',
    borderFocus: `1px solid ${sharedCustomVars.cBlueLight}`,
    borderHover: `1px solid ${sharedCustomVars.cBlueLight}`,
    placeholderColor: sharedCustomVars.cGray[500],
    textColor: sharedCustomVars.cGray[900],
  },
  LoadingBar: {
    height: '3px',
  },
  Checkbox: {
    boxShadowFocus: 'none',
    borderRadius: '4px',
  },
  Tag: {
    border: '1px solid var(--divider-color)',
    padding: '0 8px',
    heightSmall: '24px',
    textColorSuccess: success[600],
    colorSuccess: success[50],
    textColorWarning: warning[500],
    colorWarning: warning[50],
    textColorError: error[500],
    colorError: error[50],
  },
  ColorPicker: {
    borderRadius: '4px',
  },
  Form: {
    feedbackHeightSmall: '20px',
    feedbackFontSizeSmall: '12px',
    feedbackHeightMedium: '20px',
    feedbackFontSizeMedium: '12px',
    feedbackHeightLarge: '24px',
    feedbackFontSizeLarge: '14px',
  },
  Dialog: {
    iconMargin: '0 8px 0 0',
    contentMargin: '16px 0',
    padding: '20px 24px',
    borderRadius: '8px',
    closeMargin: '20px 20px 0 0',
    titleFontWeight: '500',
  },
  Breadcrumb: {
    itemTextColor: 'var(--text-color-2)',
    fontWeightActive: '600',
  },
  Typography: {
    headerFontSize2: '24px',
    headerFontSize3: '20px',
    headerFontWeight: '900',
    pMargin: '0',
  },
  Tabs: {
    // always needs to match body color
    colorSegment: sharedCustomVars.cGray[100],
  },
  Card: {
    actionColor: 'transparent',
  },
  Steps: {
    headerTextColorWait: 'var(--text-color-1)',
    headerTextColorFinish: 'var(--text-color-1)',
    splitorColorProcess: 'var(--divider-color)',
    splitorColorWait: 'var(--divider-color)',
    splitorColorFinish: 'var(--divider-color)',
    indicatorColorProcess: 'transparent',
    indicatorBorderColorProcess: 'var(--primary-color)',
    indicatorBorderColorFinish: 'var(--primary-color)',
    stepHeaderFontWeight: '500',
    indicatorSizeSmall: '16px',
    indicatorIconSizeSmall: '9px',
  },
  Progress: {
    fontSize: '12px',
    fillColor: 'var(--primary-color)',
    railColor: 'var(--divider-color)',
    railHeight: '5px',
    iconSizeLine: '14px',
  },
  Skeleton: {
    color: sharedCustomVars.cGray[100],
    colorEnd: sharedCustomVars.cGray[300],
    borderRadius: '16px',
  },
}
