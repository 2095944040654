/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseType } from './CaseType';
export type PersonalizedShowcaseRequest = {
  email: string;
  first_name: string;
  last_name: string;
  /**
   * Company name of the subscriber
   */
  company_name: string;
  language: PersonalizedShowcaseRequest.language;
  case_type: CaseType;
  /**
   * Description of the case
   */
  case_description: string;
};
export namespace PersonalizedShowcaseRequest {
  export enum language {
    AF = 'af',
    SQ = 'sq',
    EU = 'eu',
    CA = 'ca',
    CS = 'cs',
    DA = 'da',
    NL = 'nl',
    EN = 'en',
    ET = 'et',
    FI = 'fi',
    FR = 'fr',
    GL = 'gl',
    DE = 'de',
    EL = 'el',
    HU = 'hu',
    IS = 'is',
    IT = 'it',
    LB = 'lb',
    LT = 'lt',
    LV = 'lv',
    MK = 'mk',
    NO = 'no',
    PL = 'pl',
    PT = 'pt',
    RO = 'ro',
    SR = 'sr',
    SK = 'sk',
    SL = 'sl',
    ES = 'es',
    SV = 'sv',
    TR = 'tr',
  }
}

