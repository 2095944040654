<template>
  <NTooltip v-bind="mergedTooltipProps" :disabled="disableTooltip">
    <template #trigger>
      <slot name="trigger">
        <span ref="triggerRef" :class="triggerClass">{{ truncate($tolgee.unwrap(label).text, { length }) }}</span>
      </slot>
    </template>

    <!-- tooltip content -->
    <span :class="contentClass">
      {{ label }}
    </span>
  </NTooltip>
</template>

<script setup lang="ts">
import { type HTMLAttributes, computed, ref } from 'vue'
import { merge, truncate } from 'lodash-es'
import type { TooltipProps } from 'naive-ui'

export interface ITruncatedTooltipProps {
  label: string
  length?: number
  contentClass?: string
  triggerClass?: string
  tooltipProps?: TooltipProps & HTMLAttributes
}

const props = withDefaults(defineProps<ITruncatedTooltipProps>(), {
  length: 25,
})

const triggerRef = ref<HTMLElement>()

// merge tooltip props with it's defaults
const mergedTooltipProps = computed(() =>
  merge<ITruncatedTooltipProps['tooltipProps'], ITruncatedTooltipProps['tooltipProps']>(
    // defaults
    {
      style: {
        maxWidth: '320px',
      },
    },
    // incoming from props
    props.tooltipProps
  )
)

const disableTooltip = computed(() => {
  if (!triggerRef.value) return true

  return triggerRef.value.offsetWidth >= triggerRef.value.scrollWidth
})
</script>
