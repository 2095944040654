<template>
  <NResult
    status="403"
    :title="$t('generic_views.not_authorized_title')"
    :description="$t('generic_views.not_authorized_message')"
  >
    <template #footer>
      <RouterLink v-slot="{ navigate }" to="/projects">
        <NButton type="primary" @click="navigate">{{ $t('generic_views.not_authorized_redirect_me_button') }}</NButton>
      </RouterLink>
    </template>
  </NResult>
</template>
