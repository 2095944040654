import { browserTracingIntegration, init, setUser } from '@sentry/vue'
import type { App as Application } from 'vue'
import type { Router } from 'vue-router'
import type { UserDetailResponse } from '@/api'

export function useSentry(VueInstance: Application<Element>, router: Router) {
  if (import.meta.env.MODE === 'localapi' || import.meta.env.MODE === 'dev') return

  init({
    app: VueInstance,
    dsn: 'https://1243e7a948f74d4ab11ad0b557b8f567@sentry2.caplena.com/5',
    attachProps: true,
    // tracingOptions: {
    //   // this should be the way to use it, if we even use this
    //   trackComponents: ['App', 'reports.view', 'topics.view'],
    // },
    environment: import.meta.env.MODE,
    logErrors: import.meta.env.DEV,
    release: import.meta.env.MODE,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/(dev\.|stage\.|prod\.)?app\.caplena\.com\/api/],
    integrations: [browserTracingIntegration({ router })],
    // Set tracesSampleRate to 1.0 to capture 100%, of transactions for performance monitoring. We recommend adjusting this value in production
    // This is only for performance monitoring, not for error monitoring, set to 0.1 to capture 10% of transactions
    tracesSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      if (hint && breadcrumb.category === 'ui.click') {
        const { target } = hint.event

        breadcrumb.message += target.innerText ? ` | innerText: ${target.innerText.slice(0, 20)}` : ''
      }
      return breadcrumb
    },
  })
}

export function identifyUserSentry(user: UserDetailResponse | null) {
  const data = user ? { id: user.id, email: user.email } : null

  setUser(data)
}
