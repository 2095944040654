/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _AuxiliaryFieldRequest } from './_AuxiliaryFieldRequest';
import type { _TextToAnalyzeFieldRequest } from './_TextToAnalyzeFieldRequest';
import type { AnonymizePIIRequest } from './AnonymizePIIRequest';
export type ProjectCreateUIRequest = {
  /**
   * Project name, as displayed in the user interface.
   */
  name: string;
  /**
   * Project description.
   */
  description?: string;
  /**
   * Base language for this project.
   */
  language: ProjectCreateUIRequest.language;
  /**
   * Tags assigned to this project. If omitted, no tags are assigned.
   */
  tags?: Array<string>;
  /**
   * Translation engine used to translate rows into the base language of this project. If omitted, no translation will be performed.
   */
  translation_engine?: ProjectCreateUIRequest.translation_engine;
  columns?: Array<(_TextToAnalyzeFieldRequest | _AuxiliaryFieldRequest)>;
  /**
   * Replace PII with anonymized placeholders. If `None`, no anonymization is performed. If set, all values in text_to_analyze columns will be anonymized upon row creation *before translation* and any other processing.
   */
  anonymize_pii?: AnonymizePIIRequest;
};
export namespace ProjectCreateUIRequest {
  /**
   * Base language for this project.
   */
  export enum language {
    AF = 'af',
    SQ = 'sq',
    EU = 'eu',
    CA = 'ca',
    CS = 'cs',
    DA = 'da',
    NL = 'nl',
    EN = 'en',
    ET = 'et',
    FI = 'fi',
    FR = 'fr',
    GL = 'gl',
    DE = 'de',
    EL = 'el',
    HU = 'hu',
    IS = 'is',
    IT = 'it',
    LB = 'lb',
    LT = 'lt',
    LV = 'lv',
    MK = 'mk',
    NO = 'no',
    PL = 'pl',
    PT = 'pt',
    RO = 'ro',
    SR = 'sr',
    SK = 'sk',
    SL = 'sl',
    ES = 'es',
    SV = 'sv',
    TR = 'tr',
  }
  /**
   * Translation engine used to translate rows into the base language of this project. If omitted, no translation will be performed.
   */
  export enum translation_engine {
    GOOGLE_TRANSLATE = 'google_translate',
    DEEPL = 'deepl',
  }
}

