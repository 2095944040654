/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _TopicRequest } from './_TopicRequest';
import type { TextToAnalyzeMetadata } from './TextToAnalyzeMetadata';
export type _TextToAnalyzeFieldRequest = {
  /**
   * Human-readable identifier for this column. When retrieving, filtering or uploading rows, a columns' reference is used to identify it. If not specified, a reference is automatically generated. The reference field is immutable and is unique among all columns within the same project.
   */
  ref?: string;
  /**
   * Human-readable name for this column.
   */
  name: string;
  /**
   * The columns of a project define its schema. In a sense, every [project column](https://caplena.com/docs/knowledge-base/ezyy60tig7uzy-data-structure-projects-text-to-analyze-rows-etc) corresponds to exactly one column in an Excel sheet. The column type `text_to_analyze` corresponds to a column with text data that you wish to analyze. Every project must contain at least one `text_to_analyze` column.
   */
  type: _TextToAnalyzeFieldRequest.type;
  /**
   * Column description displayed for this column.
   */
  description?: string;
  /**
   * Topics associated with this column. If not specified, the topics are empty for this column. The maximum defined here applies to the maximum number of topics with `sentiment_enabled=false`. With `sentiment_enabled=true`, the maximum of topics is a third of this number. Depending on user and column settings, this limit may be higher.
   */
  topics?: Array<_TopicRequest>;
  /**
   * Metadata associated with this column.
   */
  metadata?: TextToAnalyzeMetadata;
};
export namespace _TextToAnalyzeFieldRequest {
  /**
   * The columns of a project define its schema. In a sense, every [project column](https://caplena.com/docs/knowledge-base/ezyy60tig7uzy-data-structure-projects-text-to-analyze-rows-etc) corresponds to exactly one column in an Excel sheet. The column type `text_to_analyze` corresponds to a column with text data that you wish to analyze. Every project must contain at least one `text_to_analyze` column.
   */
  export enum type {
    TEXT_TO_ANALYZE = 'text_to_analyze',
  }
}

