import {
  AvailableWidgetId,
  type NumericalDistributionHistogramWidgetResponse,
  type NumericalDistributionInsightElementResponse,
  type NumericalDistributionSegmentBucketValues,
  type NumericalDistributionSegmentCoreNumbers,
} from '@/api'

export const mockNumericalDistributionHistogramWidgetResponse: NumericalDistributionHistogramWidgetResponse = {
  kind: 'NumericalDistributionInsightHistogram',
  id: 'tbh_1',
  name: 'Numerical Distribution Histogram',
  insight_element_id: 'tb_1',
  available_identifier: AvailableWidgetId.TOPIC_BREAKOUT_HEATMAP,
  datasources: [],
  widget_config: {
    id: 'wg_1',
    widget_id: 'wg_1',
    bucket_configs: [],
  },
}

export const numericalDistributionMock: NumericalDistributionInsightElementResponse = {
  kind: 'NumericalDistributionInsight',
  id: 'tb_1',
  section_id: 'sb_1',
  order_id: 1,
  created: '2024-01-01',
  last_modified: '2024-01-01',
  filters: [],
  available_filters: {},
  numerical_distribution_histogram: mockNumericalDistributionHistogramWidgetResponse,
  numerical_distribution_core_numbers: {
    kind: 'NumericalDistributionInsightCoreNumbers',
    id: 'ncn_1',
    name: 'Core Numbers',
    insight_element_id: 'tb_1',
    available_identifier: AvailableWidgetId.NUMERICAL_DISTRIBUTION_CORE_NUMBERS,
    datasources: [],
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
  },
}

export const mockNumericalDistributionSegmentBucketValues: NumericalDistributionSegmentBucketValues = {
  kind: 'numerical-distribution-segment-bucket-values',
  warnings: [],
  description: 'Distribution of numerical values across segments',
  results: {
    default: {
      buckets: [
        {
          label: '0-10',
          value: 150,
          value_relative: 0.15,
        },
        {
          label: '11-20',
          value: 250,
          value_relative: 0.45,
        },
        {
          label: '21-30',
          value: 300,
          value_relative: 0.3,
        },
        {
          label: '31-40',
          value: 200,
          value_relative: 0.2,
        },
        {
          label: '41-50',
          value: 100,
          value_relative: 0.1,
        },
      ],
      displayed_row_count: 0,
      total_row_count: 0,
    },
  },
}

export const mockNumericalDistributionSegmentCoreNumbers: NumericalDistributionSegmentCoreNumbers = {
  kind: 'numerical-distribution-segment-core-numbers',
  warnings: [],
  results: {
    default: {
      percentiles: {
        5: 12,
        25: 15,
        50: 18,
        75: 21,
        95: 24,
      },
      range: [0, 10],
      average: 8.2,
      standard_deviation: 2.3,
      median: 9,
    },
  },
}
