import {
  AvailableWidgetId,
  ColumnKind,
  type DriverOverviewInsightElementResponse,
  type DriverValuesInfluenceResponse,
  type DriverValuesResponse,
  WidgetKind,
} from '@/api'

export const MOCK_SEGMENT_ID = 'MOCK_SEGMENT_ID'

export const driverOverviewInsightMock: DriverOverviewInsightElementResponse = {
  id: 'ie_1',
  kind: 'DriverOverviewInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  driver_summary: {
    id: 'wg_1',
    name: 'Driver Summary',
    insight_element_id: 'ie_1',
    kind: WidgetKind.TEXT,
    available_identifier: AvailableWidgetId.DRIVER_SUMMARY,
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
  },
  driver_analysis: {
    id: 'wg_3',
    name: 'Driver Analysis',
    insight_element_id: 'ie_1',
    kind: WidgetKind.DRIVER_OVERVIEW_BAR_CHART,
    available_identifier: AvailableWidgetId.DRIVER_ANALYSIS,
    widget_config: {
      id: 'wg_3',
      widget_id: 'wg_3',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
  },
  driver_influence_analysis: {
    id: 'wg_4',
    name: 'Driver Influence Analysis',
    insight_element_id: 'ie_1',
    kind: WidgetKind.DRIVER_OVERVIEW_INFLUENCE_BAR_CHART,
    available_identifier: AvailableWidgetId.DRIVER_INFLUENCE_ANALYSIS,
    widget_config: {
      id: 'wg_4',
      widget_id: 'wg_4',
      bucket_configs: [],
    },
    datasources: [],
  },
  vertical_chart: false,
  sentiment_toggle: true,
  filters: [],
  available_filters: {},
}

export const driverValuesMock: DriverValuesResponse = {
  kind: 'topic-driver-data',
  results: [
    {
      id: 'cd_9rln5z9',
      label: 'Bad support',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        sg_o17mz1z: {
          score: 0.02,
          row_count: 14,
          row_count_relative: 14,
        },
      },
    },
    {
      id: 'cd_g4n5vz9',
      label: 'Fast / efficient',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        sg_o17mz1z: {
          score: 0.03,
          row_count: 90,
          row_count_relative: 90,
        },
      },
    },
    {
      id: 'cd_1pj7zx1',
      label: 'Friendly /nice',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        sg_o17mz1z: {
          score: 0.12,
          row_count: 12,
          row_count_relative: 60,
        },
      },
    },
    {
      id: 'cd_9klxnz1',
      label: 'Unfriendly / rude',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        sg_o17mz1z: {
          score: 0.24,
          row_count: 50,
          row_count_relative: 50,
        },
      },
    },
    {
      id: 'cd_gop85x9',
      label: 'Competent',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        sg_o17mz1z: {
          score: 0.16,
          row_count: 20,
          row_count_relative: 20,
        },
      },
    },
  ],
}

export const driverValuesInfluenceMock: DriverValuesInfluenceResponse = {
  kind: 'topic-driver-influencer-data',
  results: [
    {
      id: 'cd_9rln5z9',
      label: 'Bad support',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        [MOCK_SEGMENT_ID]: {
          positive_impact: 0.2,
          negative_impact: 0.3,
          sentiment_disabled_impact: 0.4,
        },
      },
    },
    {
      id: 'cd_g4n5vz9',
      label: 'Fast / efficient',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        [MOCK_SEGMENT_ID]: {
          positive_impact: 0.2,
          negative_impact: 0.3,
          sentiment_disabled_impact: 0.4,
        },
      },
    },
    {
      id: 'cd_1pj7zx1',
      label: 'Friendly /nice',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        [MOCK_SEGMENT_ID]: {
          positive_impact: 0.2,
          negative_impact: 0.3,
          sentiment_disabled_impact: 0.4,
        },
      },
    },
    {
      id: 'cd_9klxnz1',
      label: 'Unfriendly / rude',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        [MOCK_SEGMENT_ID]: {
          positive_impact: 0.2,
          negative_impact: 0.3,
          sentiment_disabled_impact: 0.4,
        },
      },
    },
    {
      id: 'cd_gop85x9',
      label: 'Competent',
      category: 'SERVICE',
      color: 'rgba(54,162,235,0.6)',
      values: {
        [MOCK_SEGMENT_ID]: {
          positive_impact: 0.2,
          negative_impact: 0.3,
          sentiment_disabled_impact: 0.4,
        },
      },
    },
  ],
}
